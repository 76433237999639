<footer class="footer-section">
    <div class="container">
        <div class="row d-flex" style="justify-content: space-between;">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="footer-area-content">
                    <p><i class="far fa-copyright"></i>2022 Cixcent. All Rights Reserved</p>
                </div>
            </div>
            <div class="col-xl-4 single-footer-widget" style="display:none;">
                <h3>Menu</h3>
                <ul class="footer-quick-links">
                    <li><a routerLink="/">Home</a></li>
                    <li><a routerLink="/contact">Contact</a></li>
                    <li><a routerLink="/career" style="display:none;">Career</a></li>
                </ul>
            </div>
            <div class="col-xl-6 single-footer-widget">
                <h3>Contact us</h3>
                <ul class="footer-quick-links address-link">
                    <li><a href="#"><i class="fa fa-map-marker"></i> CIXCENT TECHNOLOGIES(P) LTD
                        Plot no:109, SaiBaba Officers Colony, Hyderabad, Telangana, 500062</a></li>
                    <li><a href="mailto:support@cixcent.com"><i class="fa fa-envelope"></i> info@cixcent.com</a></li>
                    <li><a href="tel:9618806125"><i class="fa fa-phone"></i> +91-9618806125</a></li>
                </ul>
            </div>
            <div class="col-xl-3 single-footer-widget" style="display:none;">
                <h3>Find us</h3>
                <ul class="social-list">
                    <li><a href="#" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" class="instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    <li><a href="#" class="instagram" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>