import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTrimSpace]'
})
export class TrimSpaceDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input')
  onInputChange() {
    debugger
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const leadingTrimmedValue = inputElement.value.replace(/^\s+/, ''); // Remove only leading spaces

    if (inputElement.value !== leadingTrimmedValue) {
      this.renderer.setProperty(inputElement, 'value', leadingTrimmedValue);
      inputElement.dispatchEvent(new Event('input')); // Trigger Angular’s change detection
    }
  }

  // Fully trim spaces on `blur` event
  @HostListener('blur')
  onInputBlur() {
    debugger
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const fullyTrimmedValue = inputElement.value.trim(); // Trim both leading and trailing spaces

    if (inputElement.value !== fullyTrimmedValue) {
      this.renderer.setProperty(inputElement, 'value', fullyTrimmedValue);
      inputElement.dispatchEvent(new Event('input')); // Trigger Angular’s change detection
    }
  }

}
