<div class="container-md mt-5">
  <div class="form-container">
    <form [formGroup]="personalDetailsForm" class="mt-5">
      <div class="row mb-4">
        <div class="col-md-12">
          <label for="firstName" class="form-label text-dark">
            <p class="fs-5 text-dark">Name</p>
          </label>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="First Name*" formControlName="firstName" maxlength="50" alphabet appAutoFocus>
            <mat-error *ngIf="personalDetailsForm.get('firstName').hasError('required')">
              First Name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Last Name*" formControlName="lastName" maxlength="50" alphabet appAutoFocus>
            <mat-error *ngIf="personalDetailsForm.get('lastName').hasError('required')">
              Last Name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <label for="age" class="form-label text-dark">
            <p class="fs-5 text-dark">Age</p>
          </label>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Age*" formControlName="age" maxlength="3" appAutoFocus number-only>
            <mat-error *ngIf="personalDetailsForm.get('age').hasError('required')">
              Age is required
            </mat-error>
          </mat-form-field>          
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <label class="form-label text-dark">
            <p class="fs-5 text-dark">Gender</p>
          </label>
          <div class="d-flex gap-3">
            <mat-chip-list aria-label="Gender selection">
              <mat-chip 
                class="chip border border-grey w-custom"
                [ngClass]="{'selected': selectedGender === 'male'}"
                (click)="selectGender('male')"
                selectable="true"
                [removable]="false">
                Male
                <span *ngIf="selectedGender === 'male'" class="checkmark">&#10003;</span>
              </mat-chip>
              
              <mat-chip 
                class="chip border border-grey w-custom"
                [ngClass]="{'selected': selectedGender === 'female'}"
                (click)="selectGender('female')"
                selectable="true"
                [removable]="false">
                Female
                <span *ngIf="selectedGender === 'female'" class="checkmark">&#10003;</span>
              </mat-chip>
            </mat-chip-list>
            
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12 text-end">
          <button type="submit" class="btn btn-dark" (click)="setActiveTab(careerFlow.ContactInformation)">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>