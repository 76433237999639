<div class="container-md" id="contactus">
  <div class="row mt-5">
    <h3 class="text-dark" >Contact Us</h3>
    <p class="text-style">Unlock your potential with our innovative projects & assistance</p>
  </div>
  <div class="row d-flex justify-content-center mt-5">
    <div class="col-md-4">
      <div class="card h-100"  style="background-color: #F0F5FD;">
        <div class="card-body">
          <img src="assets/img/salesteam.svg" class="img-fluid mx-auto mb-3" alt="">
          <h5 class="text-dark p-2">Talk to a member of our sales team</h5>
          <p class="text-style p-2">We can give you a demo, help you choose the right plan for your team, or our projects.</p>
          <button type="button" class="btn btn-dark w-50 mx-auto mt-2">Contact us</button>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100"  style="background-color: #F0F5FD;">
        <div class="card-body">
          <img src="assets/img/support.svg" class="img-fluid mx-auto mb-3" alt="">
          <h5 class="text-dark p-2">Get product or account support</h5>
          <p class="text-style p-2 mt-1">We are here to help you with any of your needs & share best practices for getting the most out of products.</p>
          <button type="button" class="btn btn-dark w-50 mx-auto mt-2">Get Support</button>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100"  style="background-color: #F0F5FD;">
        <div class="card-body">
          <img src="assets/img/career.svg" class="img-fluid mx-auto mb-3" alt="">
          <h5 class="text-dark p-2 mt-4">Come up & build the future with us</h5>
          <p class="text-style mt-2 p-2">We believe in fostering a collaborative and inclusive environment where every team member can thrive.</p>
          <button type="button" class="btn btn-dark w-50 mx-auto mt-2">Careers</button>
        </div>
      </div>
    </div>
  </div>
  </div>
  