import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HomeComponent } from "./home.component";
import { SliderContainerComponent } from "./slider-container/slider-container.component";
import { LayoutModule } from "@angular/cdk/layout";


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: HomeComponent,
      },
    ]),
  ],
  declarations: [HomeComponent, SliderContainerComponent],
})
export class HomeModule {}
