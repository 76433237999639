<div class="container-md mt-5 py-4">
  <div class="form-container">
    <div class="row">
      <div class="col-12">
        <p class="fs-5 text-dark">Qualification details</p>
      </div>
    </div>
    <form [formGroup]="qualificationForm" class="mt-4">
      <!-- ssc details -->
      <div>
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="firstName" class="form-label text-dark">
              <p class="fs-6 text-dark">SSC/Equivalent</p>
            </label>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Year of pass</mat-label>
              <input matInput placeholder="Year of pass" formControlName="yearOfPass" maxlength="50" appAutoFocus>
              <mat-error *ngIf="qualificationForm.get('yearOfPass').hasError('required')">
                Year of pass is required.
              </mat-error>
            </mat-form-field>

          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Percentage</mat-label>
              <input matInput placeholder="Percentage" formControlName="percentage" maxlength="50" appAutoFocus>
              <mat-error *ngIf="qualificationForm.get('percentage').hasError('required')">
                Percentage is required.
              </mat-error>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>School details</mat-label>
              <input matInput placeholder="School name" formControlName="schoolName" maxlength="50" appAutoFocus>
              <mat-error *ngIf="qualificationForm.get('schoolName').hasError('required')">
                School details are required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-3 mt-4">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>City/Town</mat-label>
              <mat-select formControlName="city" required>
                <mat-option value="" disabled>Select City/Town</mat-option>
                <mat-option value="city1">City 1</mat-option>
                <mat-option value="city2">City 2</mat-option>
              </mat-select>
              <mat-error *ngIf="qualificationForm.get('city').hasError('required')">
                Please select city
              </mat-error>
            </mat-form-field>

          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>State</mat-label>
              <mat-select formControlName="state" required>
                <mat-option value="" disabled>Select State</mat-option>
                <mat-option value="state1">State 1</mat-option>
                <mat-option value="state2">State 2</mat-option>
              </mat-select>
              <mat-error *ngIf="qualificationForm.get('state').hasError('required')">
                Please select state
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <div class="form-group">
              <div class="dropzone cursor-pointer" appFileDragNDrop>
                <div class="text-wrapper text-center">
                  <img src="" alt="" class="img-fluid mb-3" />
                  <div class="centered w-100">
                    <input #fileUploader type="file" name="file" id="file"
                      accept=".jpg,.jpeg,.bmp,.gif,.png,.tiff,.mp4,.mp3" multiple formControlName="file" />
                    <p>Drop files to attach or browse</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- college details -->
      <div>
        <div class="row mb-4 mt-5">
          <div class="col-md-12">
            <label for="firstName" class="form-label text-dark">
              <p class="fs-6 text-dark">12th/Intermediate/Diploma</p>
            </label>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Year of pass</mat-label>
              <input matInput formControlName="collegeYearOfPass" placeholder="Year of pass" required>
              <mat-error *ngIf="qualificationForm.get('collegeYearOfPass').hasError('required')">
                Year of pass is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Percentage*</mat-label>
              <input matInput formControlName="collegePercentage" placeholder="Percentage*" required>
              <mat-error *ngIf="qualificationForm.get('collegePercentage').hasError('required')">
                Percentage is required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>College details</mat-label>
              <input matInput formControlName="collegeName" placeholder="College name" required>
              <mat-error *ngIf="qualificationForm.get('collegeName').hasError('required')">
                College details are required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-3 mt-4">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>City/Town</mat-label>
              <mat-select formControlName="collegeCity" required>
                <mat-option value="" disabled>Select City/Town</mat-option>
                <mat-option value="city1">City 1</mat-option>
                <mat-option value="city2">City 2</mat-option>
              </mat-select>
              <mat-error *ngIf="qualificationForm.get('collegeCity').hasError('required')">
                Please select city
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>State</mat-label>
              <mat-select formControlName="collegeState" required>
                <mat-option value="" disabled>Select State</mat-option>
                <mat-option value="state1">State 1</mat-option>
                <mat-option value="state2">State 2</mat-option>
              </mat-select>
              <mat-error *ngIf="qualificationForm.get('collegeState').hasError('required')">
                Please select state
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <div class="form-group">
              <div class="dropzone cursor-pointer" appFileDragNDrop>
                <div class="text-wrapper text-center">
                  <img src="" alt="" class="img-fluid mb-3" />
                  <div class="centered w-100">
                    <input #fileUploader formControlName="collegeFile" type="file" name="file" id="file"
                      accept=".jpg,.jpeg,.bmp,.gif,.png,.tiff,.mp4,.mp3" multiple />
                    <p>Drop files to attach or browse</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- degree details -->
      <div>
        <div class="row mb-4 mt-5">
          <div class="col-md-12">
            <label for="degree" class="form-label text-dark">
              <p class="fs-6 text-dark">Degree/Graduation</p>
            </label>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Year of pass</mat-label>
              <input matInput formControlName="degreeYear" placeholder="Year of pass" required>
              <mat-error *ngIf="qualificationForm.get('degreeYear').hasError('required')">
                Year of pass is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Percentage</mat-label>
              <input matInput formControlName="degreePercentage" placeholder="Percentage" required>
              <mat-error *ngIf="qualificationForm.get('degreePercentage').hasError('required')">
                Percentage is required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>From</mat-label>
              <input matInput formControlName="degreeFrom" placeholder="From" required>
              <mat-error *ngIf="qualificationForm.get('degreeFrom').hasError('required')">
                required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>To</mat-label>
              <input matInput formControlName="degreeTo" placeholder="To" required>
              <mat-error *ngIf="qualificationForm.get('degreeTo').hasError('required')">
                required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- University -->
      <div>
        <div class="row mb-4 mt-5">
          <div class="col-md-12">
            <label for="universityName" class="form-label text-dark">
              <p class="fs-6 text-dark">University details</p>
            </label>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>University name</mat-label>
              <input matInput formControlName="universityName" placeholder="University name" required>
              <mat-error *ngIf="qualificationForm.get('universityName').hasError('required')">
                university Name required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-3 mt-4">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>City/Town</mat-label>
              <mat-select formControlName="universityCity" required>
                <mat-option value="" disabled>Select City/Town</mat-option>
                <mat-option value="city1">City 1</mat-option>
                <mat-option value="city2">City 2</mat-option>
              </mat-select>
              <mat-error *ngIf="qualificationForm.get('universityCity').hasError('required')">
                Please select City
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>State</mat-label>
              <mat-select formControlName="universityState" required>
                <mat-option value="" disabled>Select State</mat-option>
                <mat-option value="state1">State 1</mat-option>
                <mat-option value="state2">State 2</mat-option>
              </mat-select>
              <mat-error *ngIf="qualificationForm.get('universityState').hasError('required')">
                Please select State
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <div class="form-group">
              <div class="dropzone cursor-pointer" appFileDragNDrop>
                <div class="text-wrapper text-center">
                  <img src="" alt="" class="img-fluid mb-3" />
                  <div class="centered w-100">
                    <input #fileUploader formControlName="universityFile" type="file" name="file" id="file"
                      accept=".jpg,.jpeg,.bmp,.gif,.png,.tiff,.mp4,.mp3" multiple />
                    <p>Drop files to attach or browse</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- post Graduation details -->
      <div>
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="postGraduation" class="form-label text-dark">
              <p class="fs-6 text-dark">Post Graduation</p>
            </label>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Year of pass</mat-label>
              <input matInput formControlName="postGradYear" placeholder="Year of pass" required>
              <mat-error *ngIf="qualificationForm.get('postGradYear').hasError('required')">
                Year of Pass required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Percentage</mat-label>
              <input matInput formControlName="postGradPercentage" placeholder="Percentage" required>
              <mat-error *ngIf="qualificationForm.get('postGradPercentage').hasError('required')">
                Percentage required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="postGradUniversity" class="form-label text-dark">
              <p class="fs-6 text-dark">University details</p>
            </label>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>University name</mat-label>
              <input matInput formControlName="postGradUniversity" placeholder="University name" required>
              <mat-error *ngIf="qualificationForm.get('postGradUniversity').hasError('required')">
                University Name required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-3 mt-4">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>City/Town</mat-label>
              <mat-select formControlName="postGradCity" required>
                <mat-option value="" disabled>Select City/Town</mat-option>
                <mat-option value="city1">City 1</mat-option>
                <mat-option value="city2">City 2</mat-option>
              </mat-select>
              <mat-error *ngIf="qualificationForm.get('postGradCity').hasError('required')">
                Please select city
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>State</mat-label>
              <mat-select formControlName="postGradState" required>
                <mat-option value="" disabled>Select State</mat-option>
                <mat-option value="state1">State 1</mat-option>
                <mat-option value="state2">State 2</mat-option>
              </mat-select>
              <mat-error *ngIf="qualificationForm.get('postGradState').hasError('required')">
                Please select State
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <div class="form-group">
              <div class="dropzone cursor-pointer" appFileDragNDrop>
                <div class="text-wrapper text-center">
                  <img src="" alt="" class="img-fluid mb-3" />
                  <div class="centered w-100">
                    <input #fileUploader formControlName="postGradFile" type="file" name="file" id="file"
                      accept=".jpg,.jpeg,.bmp,.gif,.png,.tiff,.mp4,.mp3" multiple />
                    <p>Drop files to attach or browse</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div formArrayName="qualificationsDetails">
        <div *ngFor="let qualification of qualificationsDetails.controls; let i = index" [formGroupName]="i">
          <div class="row mb-4">
            <div class="col-md-12">
              <label for="qualification" class="form-label text-dark">
                <p class="fs-6 text-dark">Any other Qualification/Certification</p>
              </label>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company Name</mat-label>
                <input matInput formControlName="companyName" placeholder="Company name" required>
                <mat-error *ngIf="qualification.get('companyName').hasError('required')">
                  Company name is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Position</mat-label>
                <input matInput formControlName="position" placeholder="Position" required>
                <mat-error *ngIf="qualification.get('position').hasError('required')">
                  Position is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-4 mt-2">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>From</mat-label>
                <input matInput formControlName="from" placeholder="From" required>
                <mat-error *ngIf="qualification.get('from').hasError('required')">
                  Start date is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>To</mat-label>
                <input matInput formControlName="to" placeholder="To" required>
                <mat-error *ngIf="qualification.get('to').hasError('required')">
                  End date is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="form-group">
                <div class="dropzone cursor-pointer" appFileDragNDrop>
                  <div class="text-wrapper text-center">
                    <img src="" alt="" class="img-fluid mb-3" />
                    <div class="centered w-100">
                      <input type="file" (change)="onFileChange($event, i)"
                        accept=".jpg,.jpeg,.bmp,.gif,.png,.tiff,.mp4,.mp3" multiple />
                      <p>Drop files to attach or browse</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-dark me-2" (click)="addQualification()">Add One More</button>
        <button type="button" class="btn btn-outline-dark" (click)="removeLastQualification()">Remove</button>
      </div>


      <!-- <div>
        <button type="button" class="btn btn-dark me-2" (click)="addQualification()">Add One More</button>
        <button type="button" class="btn btn-outline-dark" (click)="removeLastQualification()"
          >Remove</button>
      </div> -->

      <div class="row mb-3 mt-5">
        <div class="col-md-6 text-start">
          <button type="button" class="btn btn-outline-dark"
            (click)="redirect(careerFlow.ContactInformation)">Back</button>
        </div>
        <div class="col-md-6 text-end">
          <button type="submit" class="btn btn-dark"
            (click)="setActiveTab(careerFlow.ProfessionalDetails)">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>