<div class="container-md mt-3">
  <div class="form-container text-center">
    <div class="row mt-2">
      <div class="col-12">
        <p class="fs-1  text-dark">Contact Us</p>
        <p class="fs-5 text-muted">A member of our team will be in touch soon</p>
      </div>
    </div>
    <form [formGroup]="demoBookForm" class="mt-5">
      <div class="row mb-3 mt-5">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>First name</mat-label>
            <input matInput formControlName="firstName" placeholder="First name" required>
            <mat-error *ngIf="demoBookForm.get('firstName').hasError('required')">
              First Name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Last name</mat-label>
            <input matInput formControlName="lastName" placeholder="Last name" required>
            <mat-error *ngIf="demoBookForm.get('lastName').hasError('required')">
              Last Name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email address</mat-label>
            <input matInput formControlName="emailId" placeholder="Email address" type="email" required>
            <mat-error *ngIf="demoBookForm.get('emailId').hasError('required')">
              email is required
            </mat-error>
            <mat-error *ngIf="demoBookForm.get('emailId').hasError('email')">
              Invalid email format.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Mobile number</mat-label>
            <input matInput formControlName="mobileNumber" placeholder="Mobile number" type="tel" required>
            <mat-error *ngIf="demoBookForm.get('mobileNumber').hasError('required')">
              Mobile number is required
            </mat-error>
            <mat-error *ngIf="demoBookForm.get('mobileNumber').hasError('pattern')">
              Invalid mobile number
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Store name</mat-label>
            <input matInput formControlName="storeName" placeholder="Store name" required>
            <mat-error *ngIf="demoBookForm.get('storeName').hasError('required')">
              store Name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>State</mat-label>
            <mat-select formControlName="state">
              <mat-option value="state1">Telangana</mat-option>
              <mat-option value="state2">Andhra Pradesh</mat-option>
              <mat-option value="state3">Tamil Nadu</mat-option>
            </mat-select>
            <mat-error *ngIf="demoBookForm.get('state').hasError('required')">
              please select state
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>City/Town</mat-label>
            <mat-select formControlName="city">
              <mat-option value="city1">Hyderabad</mat-option>
              <mat-option value="city2">Mumbai</mat-option>
              <mat-option value="city3">Bangalore</mat-option>
            </mat-select>
            <mat-error *ngIf="demoBookForm.get('city').hasError('required')">
              please select city
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Which product are you most interested in?</mat-label>
            <mat-select formControlName="productInterest">
              <mat-option value="webApp">Web application</mat-option>
              <mat-option value="mobileApp">Mobile application</mat-option>
              <mat-option value="retail">Retail store Management</mat-option>
            </mat-select>
            <mat-error *ngIf="demoBookForm.get('productInterest').hasError('required')">
              please select product Interest
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Which topic best fits your need?</mat-label>
            <mat-select formControlName="topic">
              <mat-option value="webApp">Web application</mat-option>
              <mat-option value="mobileApp">Mobile application</mat-option>
              <mat-option value="retail">Retail store Management</mat-option>
            </mat-select>
            <mat-error *ngIf="demoBookForm.get('topic').hasError('required')">
              please select is topic
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-start">
          <button type="button" class="btn btn-outline-dark" (click)="redirect(PageFlow.Home)">Back</button>
        </div>
        <div class="col-md-6 text-end">
          <button type="submit" class="btn btn-dark" (click)="setActiveTab(PageFlow.Home)">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
