import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-main-blog',
  templateUrl: './main-blog.component.html',
  styleUrls: ['./main-blog.component.scss']
})
export class MainBlogComponent implements OnInit {
  currentPage: number = 1;
  blogsPerPage: number = 6;
  paginatedBlogs: any[] = [];
  totalPages: number;
  blogs = [
    {
      blogId: 1,
      imgSrc: '../../assets/img/home/blog1.svg',
      altText: 'Illustration showcasing the challenges jewellery retailers face without an integrated ERP system, including inventory mismanagement, sales inefficiencies, and financial discrepancies',
      title: 'What are the unseen financial benefits of using ERP software in jewellery retail businesses?',
      description: 'In today’s competitive jewellery retail industry, managing operations efficiently while keeping costs low is crucial for sustained growth and profitability.',
      date: '12 December, 2024',
    },
    {
      blogId: 2,
      imgSrc: '../../assets/img/home/blog2.svg',
      altText: 'Illustration showcasing the challenges jewellery retailers face without an integrated ERP system, including inventory mismanagement, sales inefficiencies, and financial discrepancies',
      title: 'What are the top challenges jewellery retailers face without an integrated ERP system?',
      description: 'Running a jewellery business involves navigating a unique set of challenges. Without an integrated ERP system like ERP24K, retailers often face significant inefficiencies that can hinder....',
      date: '25 December, 2024',
    },
    {
      blogId: 3,
      imgSrc: '../../assets/img/home/blog3.svg',
      altText: 'Illustration depicting how ERP24K helps jewellery stores scale operations efficiently while maintaining high-quality standards and excellent customer service',
      title: 'Can ERP24K Help Jewellery Stores scale operations without compromising on quality?',
      description: 'Absolutely! ERP24K is specifically designed to help jewellery stores scale their operations while maintaining high standards of quality and customer service. Here’s how it achieves that....',
      date: '30 December, 2024',
    },
    {
      blogId: 4,
      imgSrc: '../../assets/img/home/blog4.svg',
      altText: 'Illustration showing how ERP24K enhances customer relationships and boosts sales in the jewellery industry.',
      title: 'How ERP24K Enhances Customer Relationships and Improves Sales in the Jewellery Industry....',
      description: 'Running a jewellery business involves navigating a unique set of challenges. Without an integrated ERP system like ERP24K, retailers often face significant inefficiencies that can hinder growth....',
      date: '5 January, 2025',
    },
    {
      blogId: 5,
      imgSrc: '../../assets/img/home/blog5.svg',
      altText: 'Computer with cloud, technology, and security icons representing Cixcent Technologies innovative jewellery ERP solution',
      title: 'Why is Integrating ERP Software Essential for Your Jewellery Business Success?',
      description: 'In the highly competitive jewellery industry, providing exceptional customer service and driving sales are critical for success. Retailers are constantly looking for ways to improve the....',
      date: '14 January, 2025',
    },
    {
      blogId: 6,
      imgSrc: '../../assets/img/home/blog6.svg',
      altText: 'Illustration depicting the selection process for the best accounting software for a jewellery retail business.',
      title: 'How Do You Select the Most Suitable Accounting Software for Your Jewellery Retail Busin....',
      description: 'Choosing the right accounting software is a critical decision for any jewellery retail business. Given the nature of the jewellery industry—dealing with high-value items, fluctuating prices of....',
      date: '25 January, 2025',
    },
    {
      blogId: 7,
      imgSrc: '../../assets/img/home/blog7.svg',
      altText: 'Illustration showing how ERP jewellery software enhances retail business operations',
      title: 'How ERP Jewellery Software Transforms Jewellery Retail Businesses?',
      description: 'The jewellery industry is one of the most Sophisticated retail sectors, dealing with highly valuable products, fluctuating market prices, and customer expectations for quality and trust....',
      date: '2 February, 2025',
    },
    {
      blogId: 8,
      imgSrc: '../../assets/img/home/blog8.svg',
      altText: 'Illustration of ERP for the Jewellery Industry Revolutionizing Retail Jewellery Businesses with Automation and Efficiency',
      title: 'ERP for the Jewellery Industry: Revolutionizing Retail Jewellery Businesses?',
      description: 'The jewellery industry operates in a highly dynamic and competitive market, where managing inventory, tracking sales, ensuring regulatory compliance, and streamlining operations are crucial for success.....',
      date: '15 February, 2025',
    },
    {
      blogId: 9,
      imgSrc: '../../assets/img/home/blog9.svg',
      altText: 'Illustration showcasing jewellery retail software optimizing store operations and inventory management',
      title: 'Why Do Jewellery Retail Stores Need a Specialised Jewellery Retail Software?',
      description: 'In the highly competitive jewellery industry, managing inventory, sales, and customer relationships efficiently is crucial for success. Traditional retail software may not cater to the unique needs of jewellery retailers, making....',
      date: '4 March, 2025',
    },
  ];

  constructor(private router: Router,private route: ActivatedRoute) { }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.currentPage = +params['page'] || 1; // Default to page 1 if no page parameter is found
      this.totalPages = Math.ceil(this.blogs.length / this.blogsPerPage);
      this.updatePaginatedBlogs();
    });
  }

  onReadMore(blog: any) {
  switch (blog.blogId) {
    case 1: this.router.navigate(['/blog/What_are_the_unseen_financial_benefits_of_using_ERP_software_in_jewellery_retail_businesses'], { queryParams: { page: this.currentPage } });
    break;
    case 2: this.router.navigate(['/blog/What_are_the_top_challenges_jewellery_retailers_face_without_an_integrated_ERP_system'], { queryParams: { page: this.currentPage } });
    break;
    case 3: this.router.navigate(['/blog/Can_ERP24K_Help_Jewellery_Stores_scale_operations_without_compromising_on_quality_or_customer_service'], { queryParams: { page: this.currentPage } });
    break;
    case 4: this.router.navigate(['/blog/How_ERP24K_Enhances_Customer_Relationships_and_Improves_Sales_in_the_Jewellery_Industry'], { queryParams: { page: this.currentPage } });
    break;
    case 5: this.router.navigate(['/blog/Why_is_Integrating_ERP_Software_Essential_for_Your_Jewellery_Business_Success'], { queryParams: { page: this.currentPage } });
    break;
    case 6: this.router.navigate(['/blog/How_Do_You_Select_the_Most_Suitable_Accounting_Software_for_Your_Jewellery_Retail_Business'], { queryParams: { page: this.currentPage } });
    break;
    case 7: this.router.navigate(['/blog/How_ERP_Jewellery_Software_Transforms_Jewellery_Retail_Businesses'], { queryParams: { page: this.currentPage } });
    break;
    case 8: this.router.navigate(['/blog/ERP_for_the_Jewellery_Industry_Revolutionizing_Retail_Jewellery_Businesses'], { queryParams: { page: this.currentPage } });
    break;
    case 9: this.router.navigate(['/blog/Why_Do_Jewellery_Retail_Stores_Need_a_Specialised_Jewellery_Retail_Software'], { queryParams: { page: this.currentPage } });
    break;
   }
  };

  updatePaginatedBlogs() {
    const startIndex = (this.currentPage - 1) * this.blogsPerPage;
    const endIndex = startIndex + this.blogsPerPage;
    this.paginatedBlogs = this.blogs.slice(startIndex, endIndex);
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedBlogs();
    }
  }

  nextPage() {
    if (this.currentPage < Math.ceil(this.blogs.length / this.blogsPerPage)) {
      this.currentPage++;
      this.updatePaginatedBlogs();
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.updatePaginatedBlogs();
  }
}
