<div class="container-fluid pt-4 mt-3 px-5" style="margin-bottom: -150px;">
  <div class="row">
    <div class="col-lg-7 mt-3 ">
      <p class="fs-3 text-dark mt-4 me-5" style="font-weight: 600;"> About us  </p>
      <p class="display-5 text-dark me-5" style="font-weight: 600;"> Hello! We're  <span style="color: #16AA51">Cixcent</span> <br>
        
        your trusted partner in streamlining your jewelry business
      </p>
      <p class="fs-5 text-dark mt-4 me-5"> We're passionate about helping you shine.
      </p>
      <button class="btn text-white px-3 mt-5" style="background-color: #16AA51;">Free trail</button>
    </div>
  </div>
</div>

<div  class="container-fluid pt-4 mt-4 px-5">
  <div>
    <div class="card" style="background: #EEFFF5; margin-top: 20px;">
      <div class="card-body" style="display: flex; align-items: flex-start;">
    <div class="row">
      <div class="col-md-9 fs-5" style="padding-right: 10px;">
        <ul style="list-style-type: disc; padding-left: 20px; margin: 0;">
          <li class="py-2"><p class=" fs-5">At Cixcent, we understand the intricacies of the jewelry industry. Our team of experts has years of experience in creating innovative solutions that cater to your unique needs.</li>
          <li class="py-2"><p class=" fs-5">Our cutting-edge ERP software ERP24k is designed to simplify your operations, enhance efficiency, and boost profitability. With features like inventory management, order tracking, and customer relationship management, you'll be able to focus on what matters most - creating exquisite pieces that dazzle.</p></li>
          <li class="py-2"><p class=" fs-5">Our mission is to empower jewelry businesses like yours to thrive in today's competitive market. Join us in revolutionizing the industry, one sparkle at a time.</p></li>
          <li class="py-2"><p class=" fs-5">Explore our website to discover how our ERP solutions can transform your business.</p></li>
        </ul>
      </div>
      <div class="col-md-3" style="flex: 0 0 auto;">
        <div class="card-img" style="width: 100%; height: auto;">
          <img src="assets/img/team-standing.svg" alt="ERP Solutions Image" style="width: 100%; height: auto; border-radius: 8px;">
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>
</div>
<app-footer-latest></app-footer-latest>

