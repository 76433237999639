import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErrorComponent } from "./components/pages/error/error.component";
import { BlogOneComponent } from "./module/blog/blog-one/blog-one.component";
import { BlogTwoComponent } from "./module/blog/blog-two/blog-two.component";
import { BlogThreeComponent } from "./module/blog/blog-three/blog-three.component";
import { BlogFourComponent } from "./module/blog/blog-four/blog-four.component";
import { BlogSixComponent } from "./module/blog/blog-six/blog-six.component";
import { BlogFiveComponent } from "./module/blog/blog-five/blog-five.component";
import { MainBlogComponent } from "./components/layouts/main-blog/main-blog.component";
import { BlogSevenComponent } from "./module/blog/blog-seven/blog-seven.component";
import { BlogEightComponent } from "./module/blog/blog-eight/blog-eight.component";
import { BlogNineComponent } from "./module/blog/blog-nine/blog-nine.component";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () => import("./module/home/home.module").then((m) => m.HomeModule),
    data: {
      moduleName: "home",
    },
  },
  {
    path: "product",
    loadChildren: () => import("./module/product/product.module").then((m) => m.ProductModule),
    data: {
      moduleName: "product",
    },
  },
  {
    path: "company",  
    loadChildren: () => import("./module/company/company.module").then((m) => m.CompanyModule),
    data: {
      moduleName: "company",
    },
  },
  {
    path: "features",
    loadChildren: () => import("./module/features/features.module").then((m) => m.FeaturesModule),
    data: {
      moduleName: "features",
    },
  },
  // {
  //   path: "blog",
  //   loadChildren: () => import("./module/blog/blog.module").then((m) => m.BlogModule),
  //   data: {
  //     moduleName: "blog",
  //   },
  // },
  {
    path: "blog", 
    component: MainBlogComponent,
  },
  {
    path: "blog/What_are_the_unseen_financial_benefits_of_using_ERP_software_in_jewellery_retail_businesses", 
    component: BlogOneComponent,
  },
  {
    path: "blog/What_are_the_top_challenges_jewellery_retailers_face_without_an_integrated_ERP_system",
    component: BlogTwoComponent,
  },
  {
    path: "blog/Can_ERP24K_Help_Jewellery_Stores_scale_operations_without_compromising_on_quality_or_customer_service", 
    component: BlogThreeComponent,
  },
  {
    path: "blog/How_ERP24K_Enhances_Customer_Relationships_and_Improves_Sales_in_the_Jewellery_Industry", 
    component: BlogFourComponent,
  },
  {
    path: "blog/Why_is_Integrating_ERP_Software_Essential_for_Your_Jewellery_Business_Success",
    component: BlogFiveComponent,
  },
  {
    path: "blog/How_Do_You_Select_the_Most_Suitable_Accounting_Software_for_Your_Jewellery_Retail_Business", 
    component: BlogSixComponent,
  },
  {
    path: "blog/How_ERP_Jewellery_Software_Transforms_Jewellery_Retail_Businesses", 
    component: BlogSevenComponent,
  },
  {
    path: "blog/ERP_for_the_Jewellery_Industry_Revolutionizing_Retail_Jewellery_Businesses", 
    component: BlogEightComponent,
  },
  {
    path: "blog/Why_Do_Jewellery_Retail_Stores_Need_a_Specialised_Jewellery_Retail_Software", 
    component: BlogNineComponent,
  },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "**", component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
