<div class="mb-5 mt-5 py-5 pt-10">
<div class="container-md text-center">
  <div class="row mt-5">
    <div class="col-12">
      <p class="text-dark fs-2 fw-bolder ">Contact sales</p>
      <p class="fs-5 text-muted text-center mt-0">
        A member of our team will be in touch soon
      </p>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-9 mx-auto mt-3">
      <p class="p-4 fs-3 text-dark">
        Got a problem? Connect with us, and we'll find the solution
      </p>
    </div>
  </div>
  <div class="row d-flex justify-content-center mt-5">
    <div class="col-md-4 text-center">
      <div class="card-body">
        <img src="assets/img/contact.svg" class="img-fluid mx-auto mb-3" alt="Call Us Icon">
        <p class="text-muted">Call us</p>
        <p class="fs-3 text-dark">+91 9000106042</p>
      </div>
    </div>
    <div class="col-md-4 text-center">
      <div class="card-body">
        <img src="assets/img/whatsapp.svg" class="img-fluid mx-auto mb-3" alt="WhatsApp Icon">
        <p class="text-muted">WhatsApp</p>
        <p class="fs-3 text-dark">+91 9000106042</p>
      </div>
    </div>
    <div class="col-md-4 text-center">
      <div class="card-body">
        <img src="assets/img/email.svg" class="img-fluid mx-auto mb-3" alt="Email Icon">
        <p class="text-muted">Mail to</p>
        <p class="fs-3 text-dark">sales@cixcent.com</p>
      </div>
    </div>
  </div><br>
  <div class="row p-4 py-4 mt-5">
    <div class="col-md-6 text-start">
      <button type="button" class="btn btn-outline-dark" (click)="setActiveTab(PageFlow.Home)">Back</button>
    </div>
  </div>
</div>
</div>