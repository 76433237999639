<div class="container-fluid bg-black pt-3">
  <div class="container-lg">
    <div class="row">
      <div class="col-lg-3 pt-lg-5 pt-3">
        <img src="../../../assets/img/home/Company_registered.svg" alt="Company registered" class="w-200px" loading="lazy">
      </div>
      <div class="col-lg-2 col-md-6 pt-lg-5 pt-3">
        <h5 class=" fs-5 text-white">Quick links</h5>
        <ul class="text-white text-decoration-underline fs-6">
          <li> <a routerLink="/home">Home </a> </li>
          <li><a routerLink="/product/erp24k">Products</a></li>
          <li><a routerLink="/features">feature</a></li>
          <li><a routerLink="/blog/blogs">Blog</a></li>
          <li><a routerLink="/company/contact-us">Contact Us</a></li>
          <li><a routerLink="/company/faqs">FAQ</a></li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-6 pt-lg-5 pt-3">
        <h5 class=" fs-5 text-white">Social Media</h5>
          <p>
        <a class="text-white fs-6" href="https://www.facebook.com/profile.php?id=61565877611650#">
          <img src="../../../assets/img/home/facebook.svg" alt="facebook" width="30"  loading="lazy">
          Facebook
        </a>
          </p>
          <p>
        <a class="text-white fs-6"  href="https://www.facebook.com/profile.php?id=61565877611650#">
          <img src="../../../assets/img/home/instagram.svg" alt="instagram" width="30"  loading="lazy">
          Instagram
        </a>
          </p>
      </div>
      <div class="col-lg-3 col-md-6 pt-lg-5 pt-3">
        <h5 class=" fs-5 text-white">Contact</h5>
        <p class="text-white fs-6 d-flex align-items-center gap-2">
          <span class="material-symbols-outlined fs-5">
            mail
          </span>
          Email: <a href="mailto:sales@cixcent.com">sales@cixcent.com</a>
        </p>
        <p class="text-white fs-6 d-flex align-items-center gap-2">
          <span class="material-symbols-outlined fs-5">
            call
          </span>
          Phone: 040 4515 7722
        </p>
        <p class="text-white fs-6 d-flex align-items-sm-start gap-2">
          <span class="material-symbols-outlined fs-5 mt-1">
            location_on
          </span> Address: Sainikpuri, Hyderabad,
          Telangana - 500094
        </p>
      </div>
      <div class="col-lg-2 col-md-6 pt-lg-5 pt-3 d-flex justify-content-md-end">
        <div>
          <h5 class="fs-5 text-white">Our Accreditations</h5>
          <div class="d-flex justify-content-center">
            <img src="../../../assets/img/home/VAPT_ 1.svg" alt="VAPT"  loading="lazy">
          </div>
        </div>

      </div>
    </div>
    <hr class="text-white border-top-2 border-white-800">
    <div class="row">
      <div class="col d-lg-flex d-none pb-4">
      </div>
      <div class="col-sm col-12 d-flex justify-content-center align-items-centers pb-4">
        <p class="text-white">© 2024 Cixcent Technologies Pvt. Ltd.</p>
      </div>
      <div class="col d-flex justify-content-sm-end justify-content-center gap-3 pb-4">
        <a class="img-container" href="https://www.facebook.com/profile.php?id=61565877611650#">
          <img src="../../../assets/img/home/dark-facebook.svg" alt="Dark facebook" class=""  loading="lazy">
        </a>
        <a class="img-container" href="https://www.instagram.com/cixcent_technologies/">
          <img src="../../../assets/img/home/dark-instagram.svg" alt="Dark Instagram" loading="lazy">
        </a>
        <a class="img-container" href="mailto:sales@cixcent.com">
          <img src="../../../assets/img/home/dark-mail.png" alt="Mail"  loading="lazy">
        </a>
        <a class="img-container" href="whatsapp://send?phone=0000000000&text=example">
          <img src="../../../assets/img/home/dark-whatsapp.svg" alt="Whatsapp"  loading="lazy">
        </a>
      </div>
    </div>
  </div>
</div>
