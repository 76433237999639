<app-navbar-two></app-navbar-two>
<div class="page-title-area" style="padding-bottom: 36px;
    padding-top: 10px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Pix10x</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Product</a></li>
                        <li>Pix10x</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>

<section class="features-section pt-5 pb-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="features-area-content">
                    <span>Pix10x</span>
                    <h2 style="font-size: 30px;">Customer Based Digital Solutions
                        for Jewellery Industries</h2>
                    <div class="row pt-0">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="flaticon-data"></i>
                                </div>
                                <h3>About</h3>
                                <p>
                                    <li>Cloud Software</li>
                                    <li>Access 365 Days & 24/7</li>
                                    <li>Highly Secured Data</li>
                                    <li>Responsive, Suits for All Devices</li>
                                    <li>Initiative & Easy to Understand User Interface</li>
                                    <li>Available in Web for All Browsers Also in
                                        Android & iOS</li>
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="fa fa-barcode"></i>
                                </div>
                                <h3>Catalogue</h3>
                                <p>
                                    <li> High Resolutions Same Picture Quality</li>
                                    <li> Organize with Groups, Categories, Concepts & Tags
                                         Easily</li>
                                    <li> Search Images Easily by Groups, Categories, Concepts and Tags Easily</li>
                                    <li> Search Images by Favorites and Product Age</li>
                                    <li> Browse Images with Easy and Intuitive Interface</li>
                                    <li> Can Edit Images by Crop, Resize, Rotate and Zoom</li>
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="flaticon-budget"></i>
                                </div>
                                <h3>Sharing</h3>
                                <p>
                                    <li> Quickly Share Images</li>
                                    <li> Share Images with Easy Controls of Entire Album, Groups, Categories and Concepts</li>
                                    <li> Control Share with Expiry Days</li>
                                    <li> Share by Link</li>
                                    <li> Share by WhatsApp</li>
                                    <li> Share Images with Same Higher Picture Quality</li>
                                    <li> Cancel or Extend Share Any Time</li>
                                    <li> Give Access to Re-Share</li>
                                    <li> Share Unlimited Images with Search Functionality</li>
                                </p>
                            </div>
                        </div>
                        
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="fa fa-bell"></i>
                                </div>
                                <h3>Getting Shared</h3>
                                <p>
                                    <li> Browse Images Shared with You</li>
                                    <li> Advanced Search Functionality</li>
                                    <li> Can View Images All or Individual at One Place</li>
                                    <li> User Level Control to View or Hide Vendor Names</li>
                                    <li> Short list for Customer</li>
                                    <li> Stock Selection for Store Employees</li>
                                    <li> View Detailed Information for Stock Selection</li>
                                    <li> Getting Shared for Longer Duration</li>
                                    <li> Place Orders Easily with Proper Detail</li>
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="flaticon-file"></i>
                                </div>
                                <h3>Orders</h3>
                                <p>
                                    <li> Easily Share and Receive Orders Securely</li>
                                    <li> Manage Inventory via Images and Easily Share</li>
                                    <li> with Simple Link to Generate More Sales</li>
                                    <li> Manage All Your Orders at One Place</li>
                                    <li> Easily Find any Orders with Advanced Search</li>
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="fa fa-user"></i>
                                </div>
                                <h3>Service Pricing Plans</h3>
                                <p>You can add your staffs using just10x software to manage jewellery retail application
                                    smoothly.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="saying-section pt-5 pb-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="saying-title mb-0">
                <h3>Got Queries?</h3>
                <p>If you have got any Queries regarding our product, please don't hesitate to contact us.</p>
            </div>
            <div class="pricing-section pb-0 pt-0">
                <div class="pricing-btn-wrap">
                    <div class="price-btn" style="display: flex;
                    flex: row;
                    flex-direction: row;
                    justify-content: center;">
                        <a target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.pix10x.pix10x"
                            class="price-btn-one me-3">
                            Download on Playstore
                            <i class="fa fa-download"></i>
                        </a>
                        <a href="tel:+919618806125" class="price-btn-one">
                            Call Us
                            <i class="fa fa-phone"></i>
                        </a>
                        <a target="_blank" href="https://wa.me/919618806125/?text=Hi Cixcent, I have a Query about ?" style="margin-left: 10px;" class="price-btn-one">
                            Ping Us on WhatsApp
                            <i class="fab fa-whatsapp"></i>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="pricing-section pt-5 pb-5" style="display:none;">
    <div class="container">
        <div class="row align-items-center">
            <div class="saying-title">
                <h3>Subscribe to Retail Management</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et
                    dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 card-hover">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Free Plan</h3>
                    </div>

                    <div class="price">
                        ₹0/ <span>Always</span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Inventory Management</li>
                        <li><i class="fa fa-arrow-right"></i> Sales Billing</li>
                        <li><i class="fa fa-arrow-right"></i> 1 User</li>
                        <li><i class="fa fa-arrow-right"></i> No Charges</li>
                    </ul>

                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Subscribe now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 card-hover card-hover">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Montly Plan (Premium)</h3>
                    </div>

                    <div class="price">
                        ₹ 2,999/ <span>+ GST (Monthly)</span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Inventory Management</li>
                        <li><i class="fa fa-arrow-right"></i> Sales Billing</li>
                        <li><i class="fa fa-arrow-right"></i> Order Management</li>
                        <li><i class="fa fa-arrow-right"></i> Repair Management</li>
                        <li><i class="fa fa-arrow-right"></i> Supplier Management</li>
                        <li><i class="fa fa-arrow-right"></i> User Management</li>
                        <li><i class="fa fa-arrow-right"></i> Reporting</li>
                        <li><i class="fa fa-arrow-right"></i> SMS</li>
                        <li><i class="fa fa-arrow-right"></i> Worker App</li>
                    </ul>

                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Subscribe now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 card-hover">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Montly Plan (Basic)</h3>
                    </div>

                    <div class="price">
                        ₹ 1,999/<span>+ GST (Monthly)</span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Inventory Management</li>
                        <li><i class="fa fa-arrow-right"></i> Sales Billing</li>
                        <li><i class="fa fa-arrow-right"></i> Order Management</li>
                        <li><i class="fa fa-arrow-right"></i> Repair Management</li>
                        <li><i class="fa fa-arrow-right"></i> Supplier Management</li>
                        <li><i class="fa fa-arrow-right"></i> User Management</li>
                        <li><i class="fa fa-arrow-right"></i> Reporting</li>
                    </ul>

                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Subscribe now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 card-hover">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Yearly Plan (Pro)</h3>
                    </div>

                    <div class="price">
                        ₹ 19,999/<span> + GST (Yearly)</span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Inventory Management</li>
                        <li><i class="fa fa-arrow-right"></i> Sales Billing</li>
                        <li><i class="fa fa-arrow-right"></i> Order Management</li>
                        <li><i class="fa fa-arrow-right"></i> Repair Management</li>
                        <li><i class="fa fa-arrow-right"></i> Supplier Management</li>
                        <li><i class="fa fa-arrow-right"></i> User Management</li>
                        <li><i class="fa fa-arrow-right"></i> Reporting</li>
                    </ul>

                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Subscribe now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>