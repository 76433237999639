<div class="container-fluid about">
  <div class="container-md pb-5">
    <div class="row mt-5">
      <h3 class="text-color mt-5">About Us</h3>
    </div>
    <div class="row">
      <div class="col-md-9">
        <p class="display-5 text-color">
          Hello! We're <span style="color: #16AA51;">Cixcent</span> <br>
          <span class="text-nowrap">we're here to empower Jewelry Stores</span>
        </p>
        <p class="text-color">An effortless and user-friendly jewelry management software</p>
      </div>
      <div class="col-md-3 d-flex justify-content-center mt-3">
        <img src="assets/new/waving_hello.svg" class="img-fluid" alt="Waving Hello">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-body">
            <h5 class="card-title">
              <img src="assets/new/diversity.svg" class="img-fluid me-2" alt="Innovation Icon">Innovation
            </h5>
            <p class="card-text">Our success is rooted in an unwavering commitment to research and innovation, propelling our jewelry excellence for over two decades. Each piece we craft reflects our relentless pursuit of perfection, seamlessly blending innovation with tradition to create timeless beauty and sophistication.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-body">
            <h5 class="card-title">
              <img src="assets/new/diversity.svg" class="img-fluid me-2" alt="Simplicity Icon">Simplicity
            </h5>
            <p class="card-text">We believe in crafting solutions for jewelry that require no deciphering of complicated instructions. Our aim is to offer seamless and intuitive solutions that elevate the jewelry experience without the need for manuals.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-body">
            <h5 class="card-title">
              <img src="assets/new/diversity.svg" class="img-fluid me-2" alt="Customer Support Icon">Customer Support
            </h5>
            <p class="card-text">We are committed to giving voice to our jewelry partners, ensuring their concerns are heard and their challenges are met with effective solutions. Your satisfaction is our priority, and we're dedicated to resolving any issues promptly and comprehensively.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-body">
            <h5 class="card-title">
              <img src="assets/new/diversity.svg" class="img-fluid me-2" alt="Pricing Icon">Pricing
            </h5>
            <p class="card-text">At Cixcent, we firmly advocate for transparent and cost-effective pricing in technology solutions. We believe in empowering jewelry businesses with fair and straightforward pricing, ensuring access to innovative tools without hidden costs.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

