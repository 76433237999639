import { Component, OnInit } from '@angular/core';
import { Pages } from 'src/enums/pages.enum';
import { PageChangeService } from 'src/services/page-change.service';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent implements OnInit {
  activeTab = Pages.Home;
  PageFlow = Pages;
  constructor( public pageService: PageChangeService) { }

  ngOnInit() {
  }
  setActiveTab(tab: number) {
    this.pageService.pageFlow$.next(tab)
  }
}
