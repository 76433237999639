<app-slider-container></app-slider-container>
<div class="container-lg">
  <div class="row d-flex align-items-center my-5 py-3">
    <div class="col-md-7 anime-container">
      <h2 class="sub_heading  text_success fw-semibold top-side-animation">Who we are?</h2>
      <div class="left-side-animation">
        <p class="text-dark ">At Cixcent Technologies, we specialize in delivering cutting-edge ERP Solutions
          designed
          specifically for jewellery Industry. Our flagship product ERP24K, helps businesses streamline operations,
          Improve inventory management, and drive growth with a suit of tools tailored to Jewellery retailers.</p>
        <h3 class="fs-4 fw-semibold text-black mt-4">Core values</h3>
        <p class="text-black" style="margin-top: -8px;"><span class="fw-semibold">Innovation:</span> We innovate to meet the unique needs of the
          jewellery
          industry.</p>
        <p class="text-black"><span class="fw-semibold">Efficiency:</span> Our solutions simplify complex business
          processes. 
        </p>
        <p class="text-black"><span class="fw-semibold">Customer Centric:</span> We are committed to empowering our
          clients to
          succeed.</p>
      </div>
      <button class="btn btn-success mt-3 bottom-side-animation " routerLink="/company/about-us">
        <h4 class="text-white my-1 fs-6 ms-2">Learn more About Us </h4><span class="material-symbols-outlined ms-2 ">
          arrow_forward
        </span>
      </button>
    </div>
    <div class="col-md-5 d-flex justify-content-xxl-end justify-content-center mt-3 ">
      <img src="../../assets/img/home/data_analytics.svg" alt="Illustration of a computer displaying graphs and data, representing business growth with Cixcent 
      Technologies' innovative solutions" class="image_animation" loading="lazy">
    </div>
  </div>
</div>
<div class="container-fluid background_light_green">
  <div class="img-container  h-auto">
    <img src="../../assets/img/home/shadow.svg" alt="Shadow" class="">
  </div>
  <div class=" container-lg my-1">
    <div class="row py-5 my-5 d-flex justify-content-between align-items-center">
      <div class="col-md-5">
        <img src="../../assets/img/home/all_devices.svg" alt="ERP24K jewellery ERP software displayed on desktop, 
        laptop, tablet, and mobile, showcasing product modules for seamless jewellery business management"
          class="image_animation" loading="lazy">
      </div>
      <div class="col-lg-6 col-md-7 anime-container ps-4 ">
        <h2 class="sub_heading  text_success fw-semibold top-side-animation">Our Products</h2>
        <div class="right-side-animation">
          <p class="fs-4 fw-semibold text-black" >ERP24K for Retailers</p>
          <p class="fs-9 text-dark ">Tailored for jewellery Retailers, ERP24k offers seamless inventory management,
            advanced sales analytics, and real-time reporting to streamline business operations.</p>
          <h3 class="fs-4 fw-semibold text-black mt-4" >Key Benefits of ERP24K for Retailers</h3>
          <p class="text-black" style="margin-top: -8px;"><span class="fw-semibold">Seamless Integration:</span> Easily integrates with your
            existing systems,
            offering a smooth transition and minimal disruption.
          </p>
          <p class="text-black"><span class="fw-semibold">Customizable Solutions:</span> Adaptable to your specific
            needs, whether
            you're managing a single location or multiple stores across cities.
          </p>
          <p class="text-black"><span class="fw-semibold">User-Friendly Interface: </span>An easy-to-use platform that
            reduces the
            learning curve for your team, improving adoption and efficiency.</p>
          <p class="text-black"><span class="fw-semibold">Cloud-Based Solution: </span> Access your data from anywhere,
            at any time,
            ensuring business continuity and flexibility. </p>
        </div>
        <button class="btn btn-success mt-3 bottom-side-animation" routerLink="/product/erp24k">
          <h4 class="text-white my-1 ms-2 fs-6">Explore ERP24K </h4><span class="material-symbols-outlined ms-2 ">
            arrow_forward
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-lg">
  <div class="row d-flex align-items-center my-5">
    <div class="col-md-7 anime-container">
      <h2 class="sub_heading  text_success fw-semibold top-side-animation">Why Choose Cixcent Technologies?</h2>
      <div class="left-side-animation">
        <h3 class="fs-4 fw-semibold text-black mt-3" >Features</h3>
        <p class="text-black "style="margin-top: -8px;"><span class="fw-semibold">Industry Expertise:</span><br> Our ERP systems are tailored for
          jewellery Industry, making us the go-to solution for jewellery retailers.</p>
        <p class="text-black"><span class="fw-semibold">Real-Time Inventory Management:</span><br> Easily track your
          inventory and manage stock across multiple stores.</p>
        <p class="text-black"><span class="fw-semibold">Advanced Analytics and Reporting:</span><br> Get actionable
          insights with data-driven reports that help you make informed business decisions.</p>
        <p class="text-black"><span class="fw-semibold">Scalable Solutions:</span><br> Whether you have a single store
          or multiple locations, our solutions grow with your business. </p>
      </div>
      <button class="btn btn-success mt-3 bottom-side-animation" routerLink="/features">
        <h4 class="text-white my-1 ms-2 fs-6">Explore features</h4> <span class="material-symbols-outlined ms-2 ">
          arrow_forward
        </span>
      </button>
    </div>
    <div class="col-md-5 d-flex justify-content-xxl-end justify-content-center mt-3 ">
      <img src="../../assets/img/home/bussines_men.svg"  alt="Illustration of graphs, bars, and currency symbols 
      representing business growth and success with Cixcent Technologies' ERP solutions" class="image_animation"
        loading="lazy">
    </div>
  </div>
</div>
<div class="container-fluid" style="position: relative;">
  <div class="shadow-container  h-auto">
    <img src="../../assets/img/home/shadow_img2.svg" alt="Shadow2" class="">
  </div>
  <div class="container-lg position-relative">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h2 class="sub_heading text_success fw-semibold top-side-animation">From our Blog </h2>
      </div>
      <div class="d-flex align-items-center fs-6 fw-semibold" style="cursor: pointer;" (click)="onSearch()">
        <h4 class="fw-semibold my-1 fs-6">Read more</h4> <span class="material-symbols-outlined ms-2 ">
          arrow_forward
        </span>
      </div>
    </div>
    <div class="d-flex flex-column align-items-start">
          <div class="row justify-content-center mt-4" style="margin-bottom: 10%;">
            <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div class="card custom-card p-4 border-0">
                <div class="img-container1">
                  <img src="../../assets/img/home/blog1.svg"
                    atl="Computer with cloud, technology, and security icons representing Cixcent Technologies' innovative jewellery ERP solution"
                    class="img-fluid" loading="lazy">
                  <div class="img-overlay"></div>
                  <button class="read-more-btn" (click)="onReadMore()">
                    Read More
                  </button>
                </div>
                <h2 class="mt-3 fs-5" style="color: #000000; font-weight: 600;">
                  What are the unseen financial benefits of using ERP software in jewellery retail businesses?
                </h2>
                <p class="mt-1 text-dark multiline-ellipsis">
                  In today’s competitive jewellery retail industry, managing operations efficiently while keeping costs
                  low
                  is crucial for sustained growth and profitability.
                </p>
                <p class="text-dark mt-2" style="font-weight: 500;">12 December, 2024</p>
              </div>
            </div>
            <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div class="card custom-card p-4 border-0">
                <div class="img-container1">
                  <img src="../../assets/img/home/blog2.svg"
                    atl="Illustration showcasing the challenges jewellery retailers face without an integrated ERP system, including inventory mismanagement, sales inefficiencies, and financial discrepancies"
                    class="img-fluid" loading="lazy">
                  <div class="img-overlay"></div> <!-- Background overlay -->
                  <button class="read-more-btn" (click)="readMore()">
                    Read More
                  </button>
                </div>
                <h2 class="mt-3 fs-5" style="color: #000000; font-weight: 600;">
                  What are the top challenges jewellery retailers face without an integrated ERP system?
                </h2>
                <p class="mt-1 text-dark multiline-ellipsis">
                  Running a jewellery business involves navigating a unique set of challenges. Without an integrated ERP
                  system like ERP24K, retailers often face significant inefficiencies that can hinder....
                </p>
                <p class="text-dark mt-2" style="font-weight: 500;">25 December, 2024</p>
              </div>
            </div>
            <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div class="card custom-card p-4 border-0">
                <div class="img-container1">
                  <img src="../../assets/img/home/blog3.svg" atl="Illustration depicting how ERP24K helps jewellery stores scale operations efficiently while maintaining
                     high-quality standards and excellent customer service" class="img-fluid" loading="lazy">
                  <div class="img-overlay"></div>
                  <button class="read-more-btn" (click)=" onMore()">
                    Read More
                  </button>
                </div>
                <h2 class="mt-3 fs-5" style="color: #000000; font-weight: 600;">
                  Can ERP24K Help Jewellery Stores scale operations without compromising on quality?
                </h2>
                <p class="mt-1 text-dark multiline-ellipsis">
                  Absolutely! ERP24K is specifically designed to help jewellery stores scale their operations while
                  maintaining high standards of quality and customer service. Here’s how it achieves that....
                </p>
                <p class="text-dark mt-2" style="font-weight: 500;">30 December, 2024</p>
              </div>
            </div>
          </div>
       
    </div>

  </div>
</div>
<div class="container-fluid background_green_light">
  <div class="container-lg py-5">
    <div class="row py-3  d-flex justify-content-between align-items-center">
      <div class="col-md-5">
        <img src="../../assets/img/home/touch-amico.svg" alt="Illustration of a representative assisting a client on a mobile device, providing 
        customer support for Cixcent Technologies' ERP solutions" class="image_animation" loading="lazy">
      </div>
      <div class="col-lg-6 col-md-7 anime-container ps-4 ">
        <h2 class="sub_heading  text_success fw-semibold top-side-animation">Get in Touch </h2>
        <div class="right-side-animation">
          <p class="fs-4 fw-semibold text-black">Our team offers</p>
          <p class="text-black"><span class="fw-semibold">Personalized Consultation: </span> We provide free,
            personalized consultations to help you understand how ERP24K can address your specific business challenges.
          </p>
          <p class="text-black"><span class="fw-semibold">Product Demos: </span>See ERP24K in action! Schedule a live
            demo to experience how our software can streamline your operations.
          </p>
          <p class="text-black"><span class="fw-semibold">Ongoing Support: </span>Once you're on board, our support team
            will assist you with any issues or questions. We’re always here to ensure your success. </p>
          <p class="text-black"><span class="fw-semibold">Have questions or want to learn more about our ERP solutions?
              Our team is here to help. Contact us today! </span> </p>
        </div>
        <div class="d-flex gap-3 fw-semibold fs-6 mt-3 bottom-side-animation">
          <div class="card contact_card shadow">
            Email: sales@cixcent.com
          </div>
          <div class="card contact_card shadow">
            Phone: 040 4515 7722
          </div>
        </div>
        <button class="btn btn-success mt-3 bottom-side-animation" routerLink="/company/contact-us">
          <h4 class="text-white my-1 fs-6 ms-2">Contact Us</h4> <span class="material-symbols-outlined ms-2 ">
            arrow_forward
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-lg my-5 pb-5">
  <div class="row d-flex align-items-center ">
    <div class="col-lg-7 anime-container">
      <h2 class="sub_heading  text_success fw-semibold top-side-animation">Driving Success in Jewellery Retail Through
        Technology</h2>
      <div class="left-side-animation">
        <p class="text-black">At Cixcent Technologies, we are committed to revolutionizing the jewellery retail industry
          with cutting-edge technology and innovative solutions. Our mission is to empower jewellery businesses across
          India by providing tools that streamline operations, enhance customer experiences, and drive business growth.
          Here's how we have made a lasting impact</p>
      </div>
      <div class="row card-container d-flex justify-content-between mt-4 bottom-side-animation">
        <div class="col-md p-2 ">
          <div class="card bg-light-primary rounded-4 h-100 bg_green">
            <div class="card-body">
              <h3 class="fs-5 fw-medium text-success">100+ <br>
                Retailers Served
              </h3>
              <p class="text-black mt-3">Trusted by jewellery retailers across India to streamline their operations.</p>
            </div>
          </div>
        </div>
        <div class="col-md p-2">
          <div class="card bg-light-primary rounded-4 h-100 bg_violet">
            <div class="card-body">
              <h3 class="fs-5 fw-medium text_violet">Commitment to <br>
                Excellence</h3>
              <p class="text-black mt-3">We're dedicated to helping our clients achieve operational success and business
                growth.</p>
            </div>
          </div>
        </div>
        <div class="col-md p-2">
          <div class="card bg-light-primary rounded-4  h-100 bg_blue">
            <div class="card-body">
              <h3 class="fs-5 fw-medium text-primary">Comprehensive <br>
                Solutions</h3>
              <p class="text-black mt-3">From inventory management to sales analytics, we cater to every need of a
                jewellery business.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 d-flex justify-content-xxl-end justify-content-center mt-3 ">
      <img src="../../assets/img/home/growth_official.svg" alt="llustration of a rising bar graph symbolizing business growth and success in the 
      jewellery retail industry with ERP24K technology" class="image_animation" loading="lazy">
    </div>
  </div>
</div>