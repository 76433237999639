<div class="container-md mb-5 pb-5">
  <div class="form-container">
    <div class="row mt-5">
      <div class="col-12">
        <p class="fs-5 text-dark">Contact Information</p>
      </div>
    </div>
    <form [formGroup]="contactInformationForm" class="mt-4">
      <div class="row mb-3">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email ID</mat-label>
            <input matInput placeholder="Email ID" formControlName="emailId" maxlength="50" appAutoFocus>
            <mat-error *ngIf="contactInformationForm.get('emailId').hasError('required')">
              Email ID is required
            </mat-error>
            <mat-error *ngIf="contactInformationForm.get('emailId').hasError('email')">
              Invalid email format.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Mobile Number</mat-label>
            <input matInput placeholder="Mobile Number" formControlName="mobileNumber" maxlength="50" appAutoFocus>
            <mat-error *ngIf="contactInformationForm.get('mobileNumber').hasError('required')">
              Mobile number is required
            </mat-error>
            <mat-error *ngIf="contactInformationForm.get('mobileNumber').hasError('pattern')">
              Invalid mobile number
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Address</mat-label>
            <input matInput placeholder="Address" formControlName="address" maxlength="50" appAutoFocus>
            <mat-error *ngIf="contactInformationForm.get('address').hasError('required')">
              Address is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>City/Town</mat-label>
            <mat-select formControlName="city" required>
              <mat-option value="" disabled>Select a city</mat-option>
              <mat-option value="city1">City 1</mat-option>
              <mat-option value="city2">City 2</mat-option>
            </mat-select>
            <mat-error *ngIf="contactInformationForm.get('city').hasError('required')">
              City/Town is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>ZIP/Postal Code</mat-label>
            <input matInput placeholder="ZIP/Postal Code" formControlName="postalCode" maxlength="50" appAutoFocus>
            <mat-error *ngIf="contactInformationForm.get('postalCode').hasError('required')">
              ZIP/Postal Code is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>State</mat-label>
            <mat-select formControlName="state" required>
              <mat-option value="" disabled>Select a state</mat-option>
              <mat-option value="state1">State 1</mat-option>
              <mat-option value="state2">State 2</mat-option>
            </mat-select>
            <mat-error *ngIf="contactInformationForm.get('state').hasError('required')">
             State is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="input-group mb-3">
            <div class="form-floating">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Permanent Address</mat-label>
                 <input matInput placeholder="permanent Address" formControlName="permanentAddress" maxlength="50" appAutoFocus></mat-form-field>
                 <mat-error *ngIf="contactInformationForm.get('permanentAddress').hasError('permanentAddress')">
                  permanent Address required
                 </mat-error>
                </div>
            <span class="input-group-text cursor-pointer text-success" (click)="copyToPermanentFields()">copy paste</span>
          </div>
        </div>
      </div>
      
      <div class="row mb-3">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Permanent City/Town</mat-label>
            <mat-select formControlName="permanentCity" required>
              <mat-option value="" disabled>Select a city</mat-option>
              <mat-option value="city1">City 1</mat-option>
              <mat-option value="city2">City 2</mat-option>
            </mat-select>
            <mat-error *ngIf="contactInformationForm.get('permanentCity').hasError('required')">
              Permanent City/Town is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Permanent ZIP/Postal Code</mat-label>
            <input matInput placeholder="ZIP/Postal Code" formControlName="permanentPostalCode" maxlength="50" appAutoFocus>
            <mat-error *ngIf="contactInformationForm.get('permanentPostalCode').hasError('required')">
              Permanent ZIP/Postal Code is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Permanent State</mat-label>
            <mat-select formControlName="permanentState" required>
              <mat-option value="" disabled>Select a state</mat-option>
              <mat-option value="state1">State 1</mat-option>
              <mat-option value="state2">State 2</mat-option>
            </mat-select>
            <mat-error *ngIf="contactInformationForm.get('permanentState').hasError('required')">
              Permanent State is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 text-start">
          <button type="button" class="btn btn-outline-dark"  (click)="redirect(careerFlow.PersonalDetails)">Back</button>
        </div>
        <div class="col-md-6 text-end">
          <button type="submit" class="btn btn-dark" (click)="setActiveTab(careerFlow.QualificationDetails)">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>

