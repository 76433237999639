<div class="container-fluid mb-5 mt-5 py-5 pt-10">
  <div class="container">
    <div class="row">
      <div class="col-md-9 mt-3">
        <p class="fs-2 text-dark fw-bolder">Support</p>
        <p class="display-5 text-dark">Welcome! Our mission is to <span style="color: #16AA51;">assist you</span> every
          step of the way
        </p>
        <p class="text-style text-dark">An effortless and user friendly jewelry management software</p>
      </div>
      <div class="col-md-3 d-flex justify-content-center mt-3 ">
        <img src="assets/new/laptopwomen.svg" class="meditation" alt="">
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="w-100">
          <div class="card p-3 border border-gray-500">
            <p class="card-title fs-4 ">
              <img src="assets/img/schedule.svg" class="img-fluid me-2" alt="Innovation Icon">24/7 Seamless online
              support
            </p>
            <p class="card-text text-dark">Round-the-clock assistance, no breaks or holidays, for all your Jew's software
              inquiries</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="w-100">
          <div class="card p-3 border border-gray-500">
            <p class="card-title fs-4 ">
              <img src="assets/img/simplicity.svg" class="img-fluid me-2" alt="Simplicity Icon">Staff training
            </p>
            <p class="card-text text-dark">Empower your team with complimentary software training, available both on-call and
              in-person as needed</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="w-100">
          <div class="card p-3 border border-gray-500">
            <p class="card-title fs-4 ">
              <img src="assets/img/Category.svg" class="img-fluid me-2" alt="Innovation Icon">On ground assistance
            </p>
            <p class="card-text text-dark">Receive personalized solutions and one-on-one guidance from our Jew's software experts
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="w-100">
          <div class="card p-3 border border-gray-500">
            <p class="card-title fs-4 ">
              <img src="assets/img/simplicity.svg" class="img-fluid me-2" alt="Simplicity Icon">Breaking language
              barriers
            </p>
            <p class="card-text text-dark">Our support team assists you in over 3 Indian languages, ensuring you're always
              understood</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="w-100">
          <div class="card p-3 border border-gray-500">
            <p class="card-title fs-4 ">
              <img src="assets/img/support_agent.svg" class="img-fluid me-2" alt="Innovation Icon">Seamless query management
            </p>
            <p class="card-text text-dark">Your questions receive a unique ticket ID and are directed to the perfect department for expert assistance
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-9 mx-auto mt-3">
        <p class="p-4 fs-3 text-dark">
          Got a problem? Connect with us, and we'll find the solution
        </p>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-5">
      <div class="col-md-4 text-center">
        <div class="card-body">
          <img src="assets/img/contact.svg" class="img-fluid mx-auto mb-3" alt="Call Us Icon">
          <p class="text-muted">Call us</p>
          <p class="fs-3 text-dark">+91 9000106042</p>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card-body">
          <img src="assets/img/whatsapp.svg" class="img-fluid mx-auto mb-3" alt="WhatsApp Icon">
          <p class="text-muted">WhatsApp</p>
          <p class="fs-3 text-dark">+91 9000106042</p>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card-body">
          <img src="assets/img/email.svg" class="img-fluid mx-auto mb-3" alt="Email Icon">
          <p class="text-muted">Mail to</p>
          <p class="fs-3 text-dark">sales@cixcent.com</p>
        </div>
      </div>
    </div>
    <div class="row p-4 py-4 mt-5">
      <div class="col-md-6 text-start">
        <button type="button" class="btn btn-outline-dark" (click)="setActiveTab(PageFlow.Home)">Back</button>
      </div>
    </div>
  </div>
</div>