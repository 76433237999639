import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Careers } from 'src/enums/pages.enum';
import { PageChangeService, validateFormFields } from 'src/services/page-change.service';

@Component({
  selector: 'app-qualification-details',
  templateUrl: './qualification-details.component.html',
  styleUrls: ['./qualification-details.component.scss']
})
export class QualificationDetailsComponent implements OnInit {
  qualificationForm: FormGroup;
  activeTab = Careers.PersonalDetails;
  careerFlow = Careers;

  constructor(private fb: FormBuilder,
    public pageService: PageChangeService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.qualificationForm = this.fb.group({
      yearOfPass: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{4}$')]),
      percentage: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      schoolName: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      city: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      file: new FormControl(null, Validators.required),
      // College Details
      collegeYearOfPass: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{4}$')]),
      collegePercentage: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      collegeName: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      collegeCity: new FormControl(null, Validators.required),
      collegeState: new FormControl(null, Validators.required),
      collegeFile: new FormControl(null, Validators.required),
      // Degree Details
      degreeYear: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{4}$')]),
      degreePercentage: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      degreeFrom: new FormControl(null, Validators.required),
      degreeTo: new FormControl(null, Validators.required),
      // University Details
      universityName: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{4}$')]),
      universityCity: new FormControl(null, Validators.required),
      universityState: new FormControl(null, Validators.required),
      universityFile: new FormControl(null),
      // Post Graduation Details
      postGradYear: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{4}$')]),
      postGradPercentage: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      postGradUniversity: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      postGradCity: new FormControl(null, Validators.required),
      postGradState: new FormControl(null, Validators.required),
      postGradFile: new FormControl(null, Validators.required),
      qualifications: new FormArray([])
      // Qualifications/Certifications
      // qualifications: this.fb.array([])
    });
    // this.qualificationForm = this.fb.group({
    //   qualifications: this.fb.array([this.createQualification()])
    // });
    // this.redirect(7);
    this.addQualification();
  }

  // get qualifications(): FormArray {
  //   return this.qualificationForm.get('qualifications') as FormArray;
  // }

  get f() {
    return this.qualificationForm.controls;
  }

  get qualificationsDetails() {
    return this.f.qualifications as FormArray;
  }

  get otherDetailsForm() {
    return this.qualificationsDetails.controls as FormGroup[];
  }

  createQualification(): FormGroup {
    return this.fb.group({
      companyName: [null, Validators.required],
      position: [null, Validators.required],
      from: [null, Validators.required],
      to: [null, Validators.required],
      files: [null]
    });
  }

  addQualification(): void {
    const qualification = this.fb.group({
      companyName: [null, Validators.required],
      position: [null, Validators.required],
      from: [null, Validators.required],
      to: [null, Validators.required],
      files: [null]
    });
    this.qualificationsDetails.push(qualification);
    this.cdr.detectChanges();
  }

  removeLastQualification(): void {
    if (this.qualificationsDetails.length > 1) {
      this.qualificationsDetails.removeAt(this.qualificationsDetails.length - 1);
    }
  }

  onFileChange(event: any, index: number): void {
    // const file = event.target.files;
    // const control = this.qualifications.at(index).get('files');
    // control?.setValue(file);
  }

  redirect(tab: number) {
    this.pageService.careerFlow$.next(tab);
  }

  setActiveTab(tab: number) {
    if (this.qualificationForm.invalid) {
      validateFormFields(this.qualificationForm);
      return;
    }
    this.pageService.careerFlow$.next(tab)
  }
}