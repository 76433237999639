export enum Pages {
    Home = 1,
    Product =2,
    Support = 3,
    Aboutus = 4,
    BookDemo = 5,
    ContactSales = 6,
    ContactSupport = 7,
    Career = 8
}
export enum Careers{
    PersonalDetails = 1,
    ContactInformation = 2,
    QualificationDetails = 3,
    ProfessionalDetails = 4,
    Preview = 5,
    Complete = 6
}