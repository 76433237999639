import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Pages } from 'src/enums/pages.enum';
import { PageChangeService, validateFormFields } from 'src/services/page-change.service';

@Component({
  selector: 'app-demo-form',
  templateUrl: './demo-form.component.html',
  styleUrls: ['./demo-form.component.scss']
})
export class DemoFormComponent implements OnInit {
  demoBookForm: FormGroup;
  activeTab = Pages.Home;
  PageFlow = Pages;
  constructor(private fb: FormBuilder,
    public pageService: PageChangeService
  ) { }

  ngOnInit() {
    this.demoBookForm = this.fb.group({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      emailId: new FormControl(null, [Validators.required, Validators.email]),
      mobileNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
      storeName: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      productInterest: new FormControl(null, [Validators.required]),
      topic: new FormControl(null, [Validators.required]),
    });
    this.redirect;
  }
  get f() {
    return this.demoBookForm.controls;
  }
  setActiveTab(tab: number) {
    if(this.demoBookForm.invalid){
      validateFormFields(this.demoBookForm);
      return;
    }
    this.pageService.pageFlow$.next(tab)
  }
  redirect(tab: number) {
    this.pageService.pageFlow$.next(tab);
  }
}
