<div class="space">

</div><br>
<div class="d-flex justify-content-center mt-4 py-4 pt-5 w-100">
  <ul class="nav nav-tabs custom-nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link px-4" [ngClass]="{'active-tab':activeTab === careerFlow.PersonalDetails}"
        (click)="setActiveTab(careerFlow.PersonalDetails)" type="button" role="tab">
        Personal details
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link px-4" [ngClass]="{'active-tab':activeTab === careerFlow.ContactInformation}"
        (click)="setActiveTab(careerFlow.ContactInformation)" type="button" role="tab">
        Contact information
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link px-4" [ngClass]="{'active-tab':activeTab === careerFlow.QualificationDetails}"
        (click)="setActiveTab(careerFlow.QualificationDetails)" type="button" role="tab">
        Qualification details
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link px-4" [ngClass]="{'active-tab':activeTab === careerFlow.ProfessionalDetails}"
        (click)="setActiveTab(careerFlow.ProfessionalDetails)" type="button" role="tab">
        Professional details
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link px-4" [ngClass]="{'active-tab':activeTab === careerFlow.Preview}"
        (click)="setActiveTab(careerFlow.Preview)" type="button" role="tab">
        Preview
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link px-4" [ngClass]="{'active-tab':activeTab === careerFlow.Complete}"
        (click)="setActiveTab(careerFlow.Complete)" type="button" role="tab">
        Complete
      </button>
    </li>
  </ul>
</div>

<div>
  <ng-template [ngIf]="activeTab === careerFlow.PersonalDetails">
    <app-personal-details></app-personal-details>
  </ng-template>
  <ng-template [ngIf]="activeTab === careerFlow.ContactInformation">
    <app-contact-information></app-contact-information>
  </ng-template>
  <ng-template [ngIf]="activeTab === careerFlow.QualificationDetails">
    <app-qualification-details></app-qualification-details>
  </ng-template>
  <ng-template [ngIf]="activeTab === careerFlow.ProfessionalDetails">
    <app-professional-details></app-professional-details>
  </ng-template>
  <ng-template [ngIf]="activeTab === careerFlow.Preview">
    <app-preview></app-preview>
  </ng-template>
  <ng-template [ngIf]="activeTab === careerFlow.Complete">
    <app-complete></app-complete>
  </ng-template>
</div>