import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Careers } from 'src/enums/pages.enum';
import { PageChangeService, validateFormFields } from 'src/services/page-change.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {
  personalDetailsForm: FormGroup;
  selectedGender: string = '';
  activeTab = Careers.PersonalDetails;
  careerFlow = Careers;

  constructor(private fb: FormBuilder,
    public pageService: PageChangeService
  ) { }

  ngOnInit() {
    this.personalDetailsForm = this.fb.group({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      age: new FormControl(null, [Validators.required, Validators.min(0), Validators.pattern(/^\d{1,3}$/)])
    });
  }

  selectGender(gender: string) {
    this.selectedGender = gender;
  }

  setActiveTab(tab: number) {
    if (this.personalDetailsForm.invalid) {
      validateFormFields(this.personalDetailsForm);
      return;
    }
    this.pageService.careerFlow$.next(tab)
  }
}
