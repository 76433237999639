import { Component, OnInit } from "@angular/core";
import { Careers, Pages } from "src/enums/pages.enum";
import { PageChangeService } from "src/services/page-change.service";

@Component({
  selector: "app-career-details",
  templateUrl: "./career-details.component.html",
  styleUrls: ["./career-details.component.scss"],
})
export class CareerDetailsComponent implements OnInit {
  activeTab = Careers.PersonalDetails;
  isScrolled: boolean = false;
  PageFlow = Pages;
  careerFlow = Careers;
  isSticky: boolean = false;
  constructor(public pageService: PageChangeService) {}

  ngOnInit() {
    const pageFlowSub = this.pageService.careerFlow$.subscribe(
      (page: Careers) => {
        this.activeTab = page;
      }
    );
  }
  setActiveTab(tab: number) {
    this.activeTab = tab;
  }
}
