<div class="background-container">
  <div class="container" style="margin-top: 65px; margin-bottom: 20%;">
    <div class="row">
      <div class="col-lg-7 d-flex flex-column justify-content-center align-items-start">
        <h1 class="display-1 text-white me-5 main_heading" style="font-weight: 600;">From our Blog</h1>
        <p class="text-white mt-3" style="font-weight: 300;">
          Stay ahead in the world of business technology by keeping up with the latest innovations in ERP solutions,
          business management practices, and newest features of our software offerings. Stay connected to all of our
          advancements and updates, ensuring you're always in the loop on how we’re enhancing your business operations.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-column align-items-start min-vh-100">
  <div class="container-lg mt-5">
    <div class="row justify-content-center" style="margin-top: 6%;">
      <div *ngFor="let blog of paginatedBlogs" class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 pb-2 mb-5 d-flex justify-content-center">
        <div class="card p-4 border-0">
          <div class="img-container">
            <img [src]="blog.imgSrc" class="img-fluid" loading="lazy" [alt]="blog.altText">
            <div class="img-overlay"></div> <!-- Background overlay -->
            <button class="read-more-btn" (click)="onReadMore(blog)">
              Read More
            </button>
          </div>
          <h2 class="fs-5 mt-3 text-dark fw-semibold mt-2">
            {{blog.title}}
          </h2>
          <p class="fs-9 text-dark multiline-ellipsis">
            {{blog.description}}
          </p>
          <p class="text-dark fw-medium mb-2">{{blog.date}}</p>
        </div>
      </div>
      </div>
      <div class="pagination" style="margin-top: 2%; margin-bottom: 5%;">
        <div class="pagination-button" *ngIf="currentPage !== 1">
          <button (click)="previousPage()" class="btn">
            <span class="material-symbols-outlined">
              arrow_back
            </span>
            Previous
          </button>
        </div>
        <span *ngFor="let page of [].constructor(totalPages); let i = index" (click)="goToPage(i + 1)" [class.active]="i + 1 === currentPage" class="page-number">
          {{i + 1}}
        </span>
        <div class="pagination-button" *ngIf="currentPage !== totalPages">
          <button (click)="nextPage()" class="btn">
            Next
            <span class="material-symbols-outlined">
              arrow_forward
            </span>
          </button>
        </div>
      </div>
  </div>
</div>