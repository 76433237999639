import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Careers } from 'src/enums/pages.enum';
import { PageChangeService, validateFormFields } from 'src/services/page-change.service';

@Component({
  selector: 'app-professional-details',
  templateUrl: './professional-details.component.html',
  styleUrls: ['./professional-details.component.scss']
})
export class ProfessionalDetailsComponent implements OnInit {
  professionalDetailsForm: FormGroup;
  selectedExperience: string = '';
  showFileUpload = true;
  showUrlInputField = false;
  activeTab = Careers.PersonalDetails;
  careerFlow = Careers;

  constructor(private fb: FormBuilder, public pageService: PageChangeService) { }

  ngOnInit() {
    this.professionalDetailsForm = this.fb.group({
      selectedExperience: new FormControl(null, Validators.required),
    })
    this.professionalDetailsForm = this.fb.group({
      experience: this.fb.array([this.createQualification()]),
      urlInput: [''] // Add this if you need a URL input field
    });
  }

  get experience(): FormArray {
    return this.professionalDetailsForm.get('experience') as FormArray;
  }

  createQualification(): FormGroup {
    return this.fb.group({
      companyName: ['', Validators.required],
      position: ['', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
      description: ['']
    });
  }
  isExperienceInvalid() {
    const control = this.professionalDetailsForm.get('selectedExperience');
    return control?.invalid && control?.touched;
  }
  addExperience() {
    this.experience.push(this.createQualification());
  }

  removeLastExperience(): void {
    if (this.experience.length > 1) {
      this.experience.removeAt(this.experience.length - 1);
    }
  }

  selectedWork(experience: string) {
    this.selectedExperience = experience;
    if (this.selectedExperience === 'yes') {
      this.showFileUpload = true;
      this.showUrlInputField = false;
    } else {
      this.showFileUpload = false;
      this.showUrlInputField = false;
    }
  }

  showFileInput(): void {
    this.showFileUpload = true;
    this.showUrlInputField = false;
  }

  showUrlInput(): void {
    this.showUrlInputField = true;
    this.showFileUpload = false;
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      // Add file validation logic
      console.log('Selected file:', file);
    }
  }

  redirect(tab: number) {
    this.pageService.careerFlow$.next(tab);
  }

  setActiveTab(tab: number) {
    if (this.professionalDetailsForm.invalid) {
      validateFormFields(this.professionalDetailsForm);
      return;
    }
    this.pageService.careerFlow$.next(tab);
  }
}
