<div class="container-md mt-5 pt-5">
  <div class="row">
    <div class="col-md-8 mt-3 ">
      <p class="display-5 text-dark"> Streamline your <span style="color: #16AA51;">Jewelry Business</span>
        <span class="text-nowrap"> with effortless store management</span>
      </p>
      <p class="fs-5 text-style mt-4">Is your jewelry business running smoothly or stuck in chaos ?</p>
      <p class="fs-5 text-style">Are you satisfied with the performance of your current software ?</p>
      <p class="fs-5 text-style">Try our Jew’s with a 100% satisfaction guarantee or your money back</p>
      <p class="fs-5 text-style">Effortlessly handle every aspect of your jewelry store, so you can shine as the ultimate
        Jewel Maestro</p>
      <a class="btn btn-dark px-4 p-2"  (click)="setActiveTab(PageFlow.BookDemo)">Book a free demo</a>
    </div>
    <div class="col-md-4 d-flex justify-content-center mt-3 ">
      <img src="assets/img/Group.svg" class="img-fluid laptopman" alt="">
    </div>
  </div>
</div>
<!-- product -->
<div class="container-md mt-5" id="#">
  <div class="row mt-5">
    <p class="mt-5 fs-3 fw-bolder text-dark">Retail Store Management</p>
  </div>
  <div class="row">
    <div class="col-md-9 mt-3">
      <p class="display-5 text-dark text-nowrap">
        Jewelry software crafted to meet your every need in <br><span style="color: #16AA51;">Jewelry business</span>
      </p>
      <p class="text-style">An effortless and user friendly jewelry management software</p>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/inventory.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-2 mt-4">Inventory</p>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/bag.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-4 mt-2">Sales</p>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/box.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-4 mt-2">Order</p>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/grid.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-2 mt-4">Old Jewelry</p>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/receipt.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-2 mt-4 text-nowrap">Product Estimation</p>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/diversity.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-2 mt-4 text-nowrap">Purchase & Suppliers</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/staff.svg" class="img-fluid m-1" alt="Inventory Image">
        </div>
        <p class="card-title p-4 mt-2">Staff</p>
      </div>
    </div>

    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/customer.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-2 mt-4">customer</p>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/overview.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-2 mt-4">Schemes</p>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/repair.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-4 mt-2">Repair</p>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/report.svg" class="img-fluid m-1 rounded-img" alt="">
        </div>
        <p class="card-title p-4 mt-2">Report</p>
      </div>
    </div>
  </div>
</div>
<!-- about us -->
<div class="container-fluid about" id="aboutus">
  <div class="container-md pb-5">
    <div class="row mt-5">
      <p class="text-color fs-2 fw-bolder mt-5">About Us</p>
    </div>
    <div class="row">
      <div class="col-md-9 mt-3">
        <p class="display-5 text-color">
          Hello! We're <span style="color: #16AA51;">Cixcent</span> <br>
          <span class="text-nowrap">we're here to empower Jewelry Stores</span>
        </p>
        <p class="text-color">An effortless and user-friendly jewelry management software</p>
      </div>
      <div class="col-md-3 d-flex justify-content-center mt-3">
        <img src="assets/new/waving_hello.svg" class="img-fluid" alt="Waving Hello">
      </div>
    </div>
    <div class="row">
      <p class="fs-2 text-color">Transforming the Jewelry world</p>
    </div>

    <div class="row mt-4">
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-body">
            <p class="fs-4 text-dark card-title">
              <img src="assets/img/Category.svg" class="img-fluid me-2" alt="Innovation Icon">Innovation
            </p>
            <p class="card-text">Our success is rooted in an unwavering commitment to research and innovation,
              propelling our jewelry excellence for over two decades. Each piece we craft reflects our relentless
              pursuit of perfection, seamlessly blending innovation with tradition to create timeless beauty and
              sophistication.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-body">
            <p class="fs-4 text-dark card-title">
              <img src="assets/img/simplicity.svg" class="img-fluid me-2" alt="Simplicity Icon">Simplicity
            </p>
             <p class="card-text">We believe in crafting solutions for jewelry that require no deciphering of complicated
              instructions. Our aim is to offer seamless and intuitive solutions that elevate the jewelry experience
              without the need for manuals.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-body">
            <p class="fs-4 text-dark card-title">
              <img src="assets/img/support_agent.svg" class="img-fluid me-2" alt="Customer Support Icon">Customer Support
            </p>
      <p class="card-text">We are committed to giving voice to our jewelry partners, ensuring their concerns are
              heard and their challenges are met with effective solutions. Your satisfaction is our priority, and we're
              dedicated to resolving any issues promptly and comprehensively.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-body">
            <p class="fs-4 text-dark card-title">
              <img src="assets/img/currency_rupee.svg" class="img-fluid me-2" alt="Pricing Icon">Pricing
            </p>
        <p class="card-text">At Cixcent, we firmly advocate for transparent and cost-effective pricing in technology
              solutions. We believe in empowering jewelry businesses with fair and straightforward pricing, ensuring
              access to innovative tools without hidden costs.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="space"></div>
<!-- contact us -->
<div class="container-md pb-5 mt-5 py-4" id="contactus">
  <div class="row mt-5">
    <p class="fs-2 text-dark fw-bolder">Contact Us</p>
    <p class="text-style">Unlock your potential with our innovative projects & assistance</p>
  </div>
  <div class="row d-flex justify-content-center mt-5 mb-3">
    <div class="col-md-4">
      <div class="card h-100" style="background-color: #F0F5FD; border-radius: 10px;">
        <div class="card-body">
          <img src="assets/img/salesteam.svg" class="img-fluid mx-auto mb-3 rounded" alt="">
          <p class="fs-4 fw-bolder text-dark p-2">Talk to a member of our sales team</p>
          <p class="text-style p-2">We can give you a demo, help you choose the right plan for your team, or our projects.</p>
          <button type="button" class="btn btn-dark w-50 mx-auto mt-2" (click)="setActiveTab(PageFlow.ContactSales)">Contact us</button>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100" style="background-color: #F0F5FD; border-radius: 10px;">
        <div class="card-body">
          <img src="assets/img/support.svg" class="img-fluid mx-auto mb-3" alt="">
          <p class="fs-4 fw-bolder text-dark p-2">Get product or account support</p>
          <p class="text-style p-2 mt-1">We are here to help you with any of your needs & share best practices for getting the most out of products.</p>
          <button type="button" class="btn btn-dark w-50 mx-auto mt-2 py-2" (click)="setActiveTab(PageFlow.ContactSupport)">Get Support</button>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100" style="background-color: #F0F5FD; border-radius: 10px;">
        <div class="card-body">
          <img src="assets/img/career.svg" class="img-fluid mx-auto mb-3" alt="">
          <p class="fs-4 fw-bolder text-dark p-2 mt-4">Come up & build the future with us</p>
          <p class="text-style mt-2 p-2">We believe in fostering a collaborative and inclusive environment where every team member can thrive.</p>
          <button type="button" class="btn btn-dark mx-auto mt-2 py-2" (click)="setActiveTab(PageFlow.Career)">Careers</button>
        </div>
      </div>
    </div>
  </div>
  
</div>
<div class="space"></div>
<!-- support -->
<div class="container-fluid-md mb-5 mt-5" style="background-color: #F0F5FD;">
  <div class="container-md">
    <div class="row">
      <div class="col-md-9 mt-3">
        <p class="fs-2 fw-bolder text-dark">Support</p>
        <p class="display-5 text-dark">Welcome! Our mission is to <span style="color: #16AA51;">assist you</span> every
          step of the way
        </p>
        <p class="text-style">An effortless and user friendly jewelry management software</p>
        <div class="d-flex">
          <a class="btn btn-dark px-4 p-2 Connect-btn"  (click)="setActiveTab(PageFlow.ContactSupport)" >Connect with us</a>
        </div>
      </div>
      <div class="col-md-3 d-flex justify-content-center mt-3 mb-4">
        <img src="assets/new/laptopwomen.svg" class="meditation" alt="">
      </div>
    </div>
  </div>
</div>

<!-- footer -->
<footer class="mb-5 mt-5 pb-5">
  <div class="container-md">
    <div class="row">
      <!-- Support Links -->
      <div class="col-md-3 mt-5">
        <p class="fs-5 fw-bolder text-dark">Support</p>
        <p class="text-dark">About Us</p>
        <p class="text-dark">Contact Sales</p>
        <p class="text-dark">WhatsApp Sales</p>
      </div>
      
      <!-- Company Info -->
      <div class="col-md-4 mt-5">
        <p class="fs-5 text-dark fw-bold">Cixcent Technologies Private Limited</p>
        <span class="text-dark fs-6 d-block">Hyderabad, Telangana-500062, India.</span>
        <p class="text-dark mt-4">
          <img src="assets/new/copyright.svg" alt="Copyright Icon"> 2024 Cixcent Technologies Pvt. Ltd.
        </p>
      </div>
      
      <!-- Contact Info -->
      <div class="col-md-3 offset-md-2 mt-5">
        <p><img src="assets/new/logo.svg" class="img-fluid mb-3" alt="Logo"></p>
        <p class="d-flex align-items-center text-dark mb-2">
          <span class="material-symbols-outlined footer-img me-2">call</span>+91 9000106052
        </p>
        <p class="d-flex align-items-center text-dark mb-0">
          <span class="material-symbols-outlined footer-img me-2">mail</span>info@cixcent.com
        </p>
      </div>
    </div>
  </div>
</footer>



<!---->


