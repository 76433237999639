import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Careers } from 'src/enums/pages.enum';
import { PageChangeService, validateFormFields } from 'src/services/page-change.service';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent implements OnInit {
  contactInformationForm: FormGroup;
  activeTab = Careers.PersonalDetails;
  careerFlow = Careers;

  constructor(private fb: FormBuilder,
    public pageService: PageChangeService
  ) { }

  ngOnInit() {
    this.contactInformationForm = this.fb.group({
      emailId: new FormControl(null, [Validators.required, Validators.email]),
      mobileNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
      address: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      postalCode: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      permanentAddress: new FormControl(null, [Validators.required]),
      permanentCity: new FormControl(null, [Validators.required]),
      permanentPostalCode: new FormControl(null, [Validators.required]),
      permanentState: new FormControl(null, [Validators.required]),
    });
  this.redirect;
  }

  copyToPermanentFields(): void {
    const formValues = this.contactInformationForm.value;
    this.contactInformationForm.patchValue({
      permanentAddress: formValues.address,
      permanentCity: formValues.city,
      permanentPostalCode: formValues.postalCode,
      permanentState: formValues.state
    });
  }

  redirect(tab: number) {
    this.pageService.careerFlow$.next(tab);
  }

  setActiveTab(tab: number) {
    if (this.contactInformationForm.invalid) {
     validateFormFields(this.contactInformationForm);
      return;
    }
    this.pageService.careerFlow$.next(tab);
  }
}
