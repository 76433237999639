<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>FAQ</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-section ptb-100">
    <div class="container">
        <div class="faq-title">
            <span>Asked Question</span>
            <h3>Frequently Ask Question</h3>
        </div>

        <div class="faq-accordion">
            <ul class="accordion">
                <li class="accordion-item">
                    <a class="accordion-title active" href="javascript:void(0)"><i class="fa fa-plus"></i> A satisfied customer is the best business strategies</a>
                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> We challenge everything</a>
                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Cooperation is our best policy</a>
                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Read the most popular Medical News & Article?</a>
                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </li>
            </ul>
        </div>
    </div>
</section>

<section class="contact-area pb-100" style="display:none;">
    <div class="container">
        <div class="section-title">
            <h2>Drop us a message for any query</h2>
            <p>If you have an idea, we would love to hear about it.</p>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" class="form-control" placeholder="Phone">
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" name="subject" id="subject" class="form-control" placeholder="Subject">
                        </div>
                    </div>
                    
                    <div class="col-lg-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" cols="30" rows="6" placeholder="Your Message"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-lg-12">
                        <button type="submit" class="default-btn">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>