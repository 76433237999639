<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Career</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Career</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="content-block mt-5">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Jobs</h2>
            <p>Come join our team!</p>
        </div>

        <div class="row">
            <div class="col-12">
                <ul class="job-positions">
                    <li class="wow fadeIn"><span>Position</span><span>Code</span><span>Location</span><span> </span>
                    </li>
                    <li class="wow fadeIn"><span>UI-UX Designer & Front-End Dev</span><span>WEBDSN001</span><span>Kiev -
                            Ukraine</span><span><a href="#">APPLY</a> </span></li>
                    <li class="wow fadeIn"><span>Social Media Manager</span><span>WEBDSN001</span><span>Odessa -
                            Ukraine</span><span><a href="#">APPLY</a> </span></li>
                    <li class="wow fadeIn"><span>SEO Specialist</span><span>WEBDSN001</span><span>Lviv -
                            Ukraine</span><span><a href="#">APPLY</a> </span></li>
                    <li class="wow fadeIn"><span>Web Product Manager</span><span>WEBDSN001</span><span>Kharkiv -
                            Ukraine</span><span><a href="#">APPLY</a> </span></li>
                    <li class="wow fadeIn"><span>Back-End Developer - JAVA</span><span>WEBDSN001</span><span>Kiev -
                            Ukraine</span><span><a href="#">APPLY</a> </span></li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Send us your Resume</h2>
            <p>If you want to join our Team, drop your Resume.</p>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" class="form-control" placeholder="Phone">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" name="subject" id="subject" class="form-control" placeholder="Subject">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="file" name="subject" id="file" class="form-control" placeholder="File">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" cols="30" rows="6"
                                placeholder="Your Message"></textarea>
                        </div>
                    </div>


                    <div class="col-lg-12">
                        <button type="submit" class="default-btn">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>