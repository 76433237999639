import { Component, HostListener, OnInit } from '@angular/core';
import { PageChangeService } from 'src/services/page-change.service';

@Component({
  selector: 'app-header-latest',
  templateUrl: './header-latest.component.html',
  styleUrls: ['./header-latest.component.scss']
})
export class HeaderLatestComponent implements OnInit {
  currentPage: string = 'home';
  isSticky: boolean = false;
  isScrolled: boolean = false;
  constructor(
    private pageService: PageChangeService
  ) { }

  ngOnInit() {
    this.pageService.newPageFlow$.subscribe((page)=>{
      this.currentPage = page
    })
  }

  setActiveTab(pageName: string,scroll?: boolean){
    this.currentPage = pageName;
    if(scroll){
      this.pageService.scrollToDemo$.next(true);
    }else{
      this.pageService.scrollToDemo$.next(false);
    }
  }

  @HostListener('window:scroll', [])
  onScroll() {
      this.isScrolled = window.scrollY > 0;
  }

  scrollPage(event: any){
    this.currentPage = 'retail';
    this.pageService.scrollToDemo$.next(true);

  }
  

}
