<nav class="navbar p-4 navbar-expand-lg bg-dark border-bottom border-body" data-bs-theme="dark">
  <div class="container-fluid ">
    <a routerLink="/home"><img src="../../../assets/img/cixcentLogo3.svg" alt="" class="cursor-pointer btn" (click)="setActiveTab('home')"  ></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-white fs-6">
        <li class="nav-item mx-4">
          <a class="nav-link " aria-current="page"  [ngClass]="{'change_page': currentPage === 'retail','text-white': currentPage !== 'retail'}"
          routerLink="/erp24k" routerLinkActive="change_page" (click)="setActiveTab('retail')">Retail</a>
        </li>
        <li class="nav-item mx-4">
          <a class="nav-link text-white" aria-current="page" [ngClass]="{'change_page': currentPage === 'wholeSale','text-white': currentPage !== 'wholeSale'}"
          (click)="setActiveTab('wholeSale')" routerLinkActive="change_page" routerLink="/wholesale">Whole Sale</a>
        </li>
        <li class="nav-item mx-4">
          <a class="nav-link text-white" aria-current="page"  [ngClass]="{'change_page': currentPage === 'aboutUs','text-white': currentPage !== 'aboutUs'}"
           (click)="setActiveTab('aboutUs')" routerLinkActive="change_page" routerLink="/aboutUs" >About Us</a>
        </li>
        <li class="nav-item mx-4 ">
          <a class="nav-link text-white" aria-current="page"   [ngClass]="{'change_page': currentPage === 'contactUs','text-white': currentPage !== 'contactUs'}"
           (click)="setActiveTab('contactUs')" routerLinkActive="change_page" routerLink="/contactUs">Contact us</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- <ng-container *ngIf="currentPage === 'home'">
  <app-bussiness (pageScroll)="scrollPage($event)"></app-bussiness>
  <app-footer-latest></app-footer-latest>
</ng-container>
<ng-container *ngIf="currentPage === 'retail'">
<app-retail-page [scrollToDemo$]="scrollToDemo$" (changePage)="setActiveTab($event)"></app-retail-page>
<app-footer-latest></app-footer-latest>
</ng-container >
<ng-container *ngIf="currentPage === 'wholeSale'">
  <app-whole-sale-latest></app-whole-sale-latest>
  <app-footer-latest></app-footer-latest>
</ng-container >
<ng-container *ngIf="currentPage === 'aboutUs'">
  <app-about-us-latest></app-about-us-latest>
  <app-footer-latest></app-footer-latest>
</ng-container >
<ng-container *ngIf="currentPage === 'contactUs'">
  <app-contact-us-latest></app-contact-us-latest>
</ng-container > -->

