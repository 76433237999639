import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us-latest',
  templateUrl: './about-us-latest.component.html',
  styleUrls: ['./about-us-latest.component.scss']
})
export class AboutUsLatestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    {}
  }

}
