<app-navbar-two></app-navbar-two>
<div class="marketing-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="hero-content">
                            <h1>Try our products to help Jewellary Business Grow!</h1>

                            <!-- <div class="marketing-btn">
                                <a routerLink="/" class="marketing-btn-one">Discover More</a>
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                                    class="video-btn popup-youtube"><span>
                                        <i class="fa fa-play"></i></span>
                                    Watch The Demo
                                </a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape6.png" alt="image"></div>
</div>

<section class="top-services-section pt-0 pb-100">
    <div class="container">
        <div class="top-services-area">
            <div class="row align-items-center">

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img src="assets/img/Album.png" alt="image">
                        <h3>Pix10x</h3>
                        <h6 style="color:white;">Hi</h6>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img src="assets/img/Retail.png" alt="image">
                        <h3>Retail Store Management</h3>
                        <h6 class="text-muted">Coming Soon</h6>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <!-- <div class="top-services-content"> -->
                        <img src="assets/img/Bullion.png" alt="image">
                        <h3>Bullion</h3>
                         <h6 class="text-muted">Coming Soon</h6>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</section>


<section class="pricing-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="saying-title">
                <h3>Our Products</h3>
                <p>Try our products to help Jewellary Business Grow!</p>
            </div>

            <div class="row pricing-layout">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-pricing-box table-price">
                        <div class="pricing-header">
                            <h3>Pix10x</h3>
                        </div>
                        <ul class="pricing-features mt-3">
                            <li><i class="fa fa-arrow-right"></i> About</li>
                            <li><i class="fa fa-arrow-right"></i> Catalogue</li>
                            <li><i class="fa fa-arrow-right"></i> Sharing</li>
                            <li><i class="fa fa-arrow-right"></i> Getting Shared</li>
                            <li><i class="fa fa-arrow-right"></i> Orders</li>
                            <li><i class="fa fa-arrow-right"></i> Service Pricing Plans</li>
                        </ul>

                        <div class="buy-btn">
                            <a routerLink="/pix10x" class="buy-btn-one">View More</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="saying-section ptb-100" style="display:none;">
    <div class="container">
        <div class="saying-title">
            <h3>What clients is saying about us</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
        </div>

        <div class="saying-slides owl-carousel owl-theme">
            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img1.jpg" alt="image">
                    </div>

                    <h4>Ketax Andon</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img2.jpg" alt="image">
                    </div>
                    <h4>Kolil Ward</h4>
                    <span>CEO Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img3.jpg" alt="image">
                    </div>
                    <h4>Jandhu Dew</h4>
                    <span>Marketing Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img4.jpg" alt="image">
                    </div>
                    <h4>Andon Smait</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-section" style="display:none;">
    <div class="container">
        <div class="faq-title">
            <span>Asked Question</span>
            <h3>Frequently Ask Question</h3>
        </div>

        <div class="faq-accordion">
            <ul class="accordion">
                <li class="accordion-item">
                    <a class="accordion-title active" href="javascript:void(0)"><i class="fa fa-plus"></i> A satisfied
                        customer is the best business strategies</a>

                    <p class="accordion-content show">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> We challenge
                        everything</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Cooperation is our
                        best policy</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Read the most
                        popular Medical News & Article?</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>
            </ul>
        </div>
    </div>
</section>

<section class="update-section inner-update-section ptb-100" style="display:none;">
    <div class="container">
        <div class="update-title">
            <h3>Get Every Single Update</h3>
        </div>
        <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form>
    </div>

    <div class="shape"><img src="assets/img/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/img/shape/shape20.png" alt="Images"></div>
</section>