<div class="container-md">
  <div class="row">
    <div class="col-md-8 mt-3 ">
      <p class="display-5 text-dark"> Streamline your <span style="color: #16AA51;">Jewelry Business</span>
        <span class="text-nowrap"> with effortless store management</span>
      </p>
      <p class="text-style mt-4">Is your jewelry business running smoothly or stuck in chaos ?</p>
      <p class="text-style">Are you satisfied with the performance of your current software ?</p>
      <p class="text-style">Try our Jew’s with a 100% satisfaction guarantee or your money back</p>
      <p class="text-style">Effortlessly handle every aspect of your jewelry store, so you can shine as the ultimate
        Jewel Maestro</p>
      <a class="btn btn-dark px-4 p-2">Book a free demo</a>
    </div>
    <div class="col-md-4 d-flex justify-content-center mt-3 ">
      <img src="assets/img/Group.svg" class="img-fluid laptopman" alt="">
    </div>
  </div>
</div>


<div class="container-md">
  <div class="row mt-5">
    <p class="fs-1 heading-2 text-dark" >Jewelry software crafted to meet your every need in <br> <span style="color: #16AA51;">Jewelry business</span>
    </p>
    <p class="text-style">An effortless and user friendly jewelry management software</p>

  </div>
  <div class="row mt-5">
  <div class="col-md-2 mt-4 d-flex align-items-stretch" >
      <div class="card-body">
        <div class="img-container">
        <img src="assets/new/inventory.svg" class="img-fluid m-1 rounded-img" alt="">
      </div>
        <h5 class="card-title p-2 mt-4">Inventory</h5>
      </div>
  </div>
  <div class="col-md-2 mt-4 d-flex align-items-stretch">
    <div class="card-body">
      <div class="img-container">
      <img src="assets/new/bag.svg" class="img-fluid m-1 rounded-img" alt="">
      </div>
      <h5 class="card-title p-4 mt-2">Sales</h5>
    </div>
  </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
        <img src="assets/new/box.svg" class="img-fluid m-1 rounded-img" alt="">
      </div>
        <h5 class="card-title p-4 mt-2">Order</h5>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
        <img src="assets/new/grid.svg" class="img-fluid m-1 rounded-img" alt="">
       </div>
       <h5 class="card-title p-2 mt-4">Old Jewelry</h5>
      </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
      <img src="assets/new/receipt.svg" class="img-fluid m-1 rounded-img" alt="">
       </div>
       <h5 class="card-title p-2 mt-4 text-nowrap">Product Estimation</h5>
    </div>
    </div>
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
        <img src="assets/new/diversity.svg" class="img-fluid m-1 rounded-img" alt="">
       </div>
       <h5 class="card-title p-2 mt-4 text-nowrap">Purchase & Suppliers</h5>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
          <img src="assets/new/staff.svg" class="img-fluid m-1" alt="Inventory Image">
        </div>
        <h5 class="card-title p-4 mt-2">Staff</h5>
      </div>
    </div>
    
    <div class="col-md-2 mt-4 d-flex align-items-stretch">
      <div class="card-body">
        <div class="img-container">
       <img src="assets/new/customer.svg" class="img-fluid m-1 rounded-img" alt="">
       </div>
       <h5 class="card-title p-2 mt-4">customer</h5>
       </div>
      </div>
      <div class="col-md-2 mt-4 d-flex align-items-stretch">
        <div class="card-body">
          <div class="img-container">
         <img src="assets/new/overview.svg" class="img-fluid m-1 rounded-img" alt="">
         </div>
         <h5 class="card-title p-2 mt-4">Schemes</h5>
        </div>
      </div>
      <div class="col-md-2 mt-4 d-flex align-items-stretch">
        <div class="card-body">
          <div class="img-container">
          <img src="assets/new/repair.svg" class="img-fluid m-1 rounded-img" alt="">
         </div>
         <h5 class="card-title p-4 mt-2">Repair</h5>
        </div>
      </div>
      <div class="col-md-2 mt-4 d-flex align-items-stretch">
        <div class="card-body">
          <div class="img-container">
          <img src="assets/new/report.svg" class="img-fluid m-1 rounded-img" alt="">
         </div>
         <h5 class="card-title p-4 mt-2">Report</h5>
        </div>
      </div>
  </div>
</div>