<app-navbar-two></app-navbar-two>

<div class="page-title-area" style="padding-bottom: 36px;
padding-top: 10px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Mobile Apps</h2>

                    <ul>
                        <li><a routerLink="/">Product</a></li>
                        <li>Mobile Apps</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MY WORKER APP -->
<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="saying-slides owl-carousel owl-theme">
                    <div class="saying-single-item">
                        <img src="assets/img/my-worker-app/my-worker-app-1.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/my-worker-app/my-worker-app-2.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/my-worker-app/my-worker-app-3.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/my-worker-app/my-worker-app-4.webp" width="25%" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content-area">
                    <span>MY WORKER APP</span>
                    <h3>Connects Retailer and Worker to each other at fingertips!</h3>
                    <p>My Worker App a jewellery connect app brings retailer and worker connect to each other at
                        fingertips! Download the My Worker App app today to connect with retailers to get work related
                        earrings, mangalsutras, necklaces, bracelets, chains, bangles and more. </p>
                    <p>Over 10,000 designs and collections for bridal, everyday wear, party, traditional, sangeet,
                        birthdays are delivered by retailer and worker.</p>
                </div>

                <div class="pricing-section pb-0 pt-0">
                    <div class="pricing-btn-wrap">
                        <div class="price-btn">
                            <a target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.pooja.pooja_worker_app"
                                class="price-btn-one">
                                Download on Playstore
                                <i class="fa fa-download"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- PIX10X -->
<section class="about-section p-5" style="background-color: #f8f8f8;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content-area">
                    <span>Pix10x</span>
                    <h3>Jewellery item collection app brings customer, retailer and wholesaler connect to each other at
                        fingertips!</h3>
                    <p>Download the pix10x app today to connect with retailers to select earrings, mangalsutras,
                        necklaces, bracelets, chains, bangles and more.</p>
                    <p>Over 10,000 designs and collections for bridal, everyday wear, party, traditional, sangeet,
                        birthdays are collections from many jewelers for their respective gallery.</p>
                    <div class="pricing-section pb-0 pt-0">
                        <div class="pricing-btn-wrap">
                            <div class="price-btn">
                                <a target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.pix10x.pix10x"
                                    class="price-btn-one">
                                    Download on Playstore
                                    <i class="fa fa-download"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="saying-slides owl-carousel owl-theme">
                    <div class="saying-single-item">
                        <img src="assets/img/pix10x/pix10x-1.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/pix10x/pix10x-2.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/pix10x/pix10x-3.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/pix10x/pix10x-4.webp" width="25%" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- POOJA jewelers -->
<section class="about-section p-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="saying-slides owl-carousel owl-theme">
                    <div class="saying-single-item">
                        <img src="assets/img/pooja-jewellers/pooja-jewellers-1.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/pooja-jewellers/pooja-jewellers-2.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/pooja-jewellers/pooja-jewellers-3.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/pooja-jewellers/pooja-jewellers-4.webp" width="25%" alt="image">
                    </div>
                    <div class="saying-single-item">
                        <img src="assets/img/pooja-jewellers/pooja-jewellers-5.webp" width="25%" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content-area">
                    <span>Pooja Jewellers</span>
                    <h3>Hyderabad’s most loved jewellery shop - Pooja Jewellers</h3>
                    <p>Hyderabad’s most loved jewellery shop - Pooja Jewellers takes yet another step closer to you
                        through this exclusive online jewellery order tracking app.</p>
                    <div class="pricing-section pb-0 pt-0">
                        <div class="pricing-btn-wrap">
                            <div class="price-btn">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.rana.PoojaJW"
                                    class="price-btn-one">
                                    Download on Playstore
                                    <i class="fa fa-download"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3>Get Every Single Update</h3>
        </div>
        <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form>
    </div>

    <div class="shape"><img src="assets/img/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/img/shape/shape20.png" alt="Images"></div>
</section>