<div class="slider-container">
  <div class="slide slide1 d-flex align-items-center ">
    <div class="container-lg">
      <div class="row d-flex justify-content-lg-between justify-content-center">
        <div class="col-md-7 mt-3 d-flex  align-items-center">
          <div>
            <h1 class="main_heading fs-1 text-dark me-5" style="font-weight: 600;"> Innovating Jewellery Retail
              with <span style="color: #16AA51;">Powerful ERP Solution</span>
            </h1>
            <p class="fs-9 text-dark mt-4 me-5">
              At Cixcent Technologies, we provide cutting-edge ERP systems to simplify and streamline operations for
              jewellery retailers. Our solutions are tailored to address the unique challenges of the jewellery
              industry,
              Our ERP software empowers you to optimize workflows, enhance customer experiences, and drive sustained
              business growth in a highly competitive market.
            </p>
          </div>
        </div>
        <div class="col-md-5 col-7 d-flex justify-content-xxl-end justify-content-center mt-3 ">
          <img src="../../assets/img/home/home_img.svg" alt="Computer with cloud, technology, and security icons representing Cixcent Technologies' innovative jewellery ERP solution" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
  <div class="slide slide2 d-flex align-items-center ">
    <div class="container-lg">
      <div class="row  d-flex justify-content-lg-between justify-content-center">
        <div class="col-md-7 mt-3 d-flex align-items-center">
          <div>
            <h2 class="main_heading text-dark me-5" style="font-weight: 600;"> Greater Efficiency with
              <span style="color: #00aaff;">ERP24K</span>

              for Retailers

            </h2>
            <p class="fs-9 text-dark mt-4 me-5">
              ERP24K is the ultimate ERP Jewellery Software designed to streamline and optimize your retail jewellery
              operations. By integrating all critical functions such as inventory management, sales tracking, customer
              relationship management, and invoicing into one powerful, intuitive platform.
            </p>
          </div>
        </div>
        <div class="col-md-5 col-7 d-flex justify-content-xxl-end justify-content-center mt-3 ">
          <img src="../../assets/img/home/efficiency_img.svg" alt="ERP24K jewellery ERP software displayed on laptop, desktop, tablet, and mobile, enhancing efficiency for jewellery retailers" class="">
        </div>
      </div>
    </div>
  </div>
  <div class="slide slide3 d-flex align-items-center ">
    <div class="container-lg">
      <div class="row  d-flex justify-content-lg-between justify-content-center">
        <div class="col-md-7 mt-3 d-flex align-items-center">
          <div>
            <h2 class="main_heading text-dark me-5" style="font-weight: 600;"> Empowering Businesses with Innovative <span
                style="color: #7845AB;">Mobile Application</span> Solutions
            </h2>
            <p class="fs-9 text-dark mt-4 me-5">
              At Cixcent Technologies, we design and develop intuitive mobile applications to enhance efficiency and
              productivity. Our solutions empower businesses with seamless functionality, real-time accessibility, and
              robust features tailored to meet dynamic operational needs, ensuring your business stays ahead in the
              digital era.
            </p>
          </div>
        </div>
        <div class="col-md-5  col-7  d-flex justify-content-xxl-end justify-content-center mt-3 ">
          <img src="../../assets/img/home/mobile_img.png" alt="ERP24K jewellery ERP mobile application displayed on two smartphones, providing seamless management for jewellery retailers" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
  <div class="dots">
    <span class="dot"></span>
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
</div>
