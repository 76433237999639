<nav class="navbar navbar-expand-lg bg-dark  py-3 sticky-header" [class.hidden]="isHidden" data-bs-theme="dark">
  <div class="container-lg d-flex justify-content-between ">
    <a routerLink="/home"><img src="../../../assets/img/cixcentLogo3.svg" alt="registered" class="cursor-pointer btn" class="header-img"></a>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-white fs-6">
        <li class="nav-item mx-4">
          <a class="nav-link " aria-current="page" routerLink="/home" routerLinkActive="change_page">Home</a>
        </li>
        <li class="nav-item dropdown mx-4">
          <a class="nav-link dropdown-toggle" role="button" [class.change_page]="isChildActive('/product/')">
            Products
          </a>
          <ul class="dropdown-menu">
            <li class="px-2"><a class="dropdown-item py-2" routerLink="/product/erp24k">ERP24K For Retailers</a></li>
            <li class="px-2"><a class="dropdown-item py-2" routerLink="/product/wholesale">Wholesalers</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown mx-4">
          <a class="nav-link dropdown-toggle" role="button" [class.change_page]="isChildActive('/company/')">
            Company
          </a>
          <ul class="dropdown-menu">
            <li class="px-2"><a class="dropdown-item py-2" routerLink="/company/about-us">About Us</a></li>
            <li class="px-2"><a class="dropdown-item py-2" routerLink="/company/contact-us">Contact Us</a></li>
            <li class="px-2"><a class="dropdown-item py-2" routerLink="/company/faqs">FAQ`s</a></li>
          </ul>
        </li>
        <li class="nav-item mx-4 ">
          <a class="nav-link" aria-current="page" routerLinkActive="change_page" routerLink="/features">Features</a>
        </li>
        <li class="nav-item mx-4 ">
          <a class="nav-link" aria-current="page" routerLinkActive="change_page" routerLink="/blog">Blog</a>
        </li>
      </ul>
    </div>
    <div>
      <span class="text-muted d-flex d-lg-none" type="button"type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop"><span
          class="material-symbols-outlined fs-1">
          menu
        </span></span>
    </div>
  </div>
</nav>

<div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="staticBackdrop" aria-labelledby="offcanvasDarkLabel">
  <div class="">
    <button type="button" class="btn-close btn-close-white m-2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-white fs-6">
      <li class="nav-item mx-4">
        <a class="nav-link " aria-current="page" routerLink="/home" routerLinkActive="change_page" data-bs-dismiss="offcanvas">Home</a>
      </li>
      <li class="nav-item dropdown mx-4">
        <a class="nav-link dropdown-toggle" role="button" [class.change_page]="isChildActive('/product/')" 
        data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
          Products
        </a>
        <ul class="collapse text-white"  id="collapseExample">
          <li class="text-white my-2 py-1"><a class="text-white" routerLink="/product/wholesale" routerLinkActive="text-decoration-underline" data-bs-dismiss="offcanvas">Wholesalers</a></li>
          <li class="text-white my-2 py-1"><a class="text-white" routerLink="/product/erp24k" routerLinkActive="text-decoration-underline" data-bs-dismiss="offcanvas">ERP24K For Retailers</a></li>
        </ul>
      </li>
      <li class="nav-item dropdown mx-4">
        <a class="nav-link dropdown-toggle" role="button" [class.change_page]="isChildActive('/company/')"
        data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
          Company
        </a>
        <ul class="collapse text-white" id="collapseExample1">
          <li class="text-white my-2 py-1"><a class="text-white" routerLinkActive="text-decoration-underline" routerLink="/company/contact-us" data-bs-dismiss="offcanvas">Contact Us</a></li>
          <li class="text-white my-2 py-1"><a class="text-white" routerLinkActive="text-decoration-underline" routerLink="/company/about-us" data-bs-dismiss="offcanvas">About Us</a></li>
          <li class="text-white my-2 py-1"><a class="text-white" routerLinkActive="text-decoration-underline" routerLink="/company/faqs" data-bs-dismiss="offcanvas">FAQ`s</a></li>
        </ul>
      </li>
      <li class="nav-item mx-4 ">
        <a class="nav-link" aria-current="page" routerLinkActive="change_page" routerLink="/features" data-bs-dismiss="offcanvas">Features</a>
      </li>
      <li class="nav-item mx-4 ">
        <a class="nav-link" aria-current="page" routerLinkActive="change_page" routerLink="/blog" data-bs-dismiss="offcanvas">Blog</a>
      </li>
    </ul>
  </div>
</div>