<div class="navbar-area-two">
    <div class="exto-responsive-nav">
        <div class="container">
            <div class="exto-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/home/logo.png" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="exto-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <!-- <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/home/logo.png" alt="logo" width="50%">
                </a> -->
                <h1 class="text-white">CIXCENT</h1>
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Our Products <i class="fa fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pix10x" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pix10x</a></li>
                                <li class="nav-item"><a class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Retail Store Management</a></li>
                                <li class="nav-item"><a class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Bullian</a></li>
                            </ul>
                        </li>
                        <li class="nav-item" style="display:none;">
                            <a href="javascript:void(0)" class="nav-link">About <i class="fa fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                                <li class="nav-item"><a routerLink="/career" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Careers</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>