<div class="modal-header bg-light-success" style="background-color: #e6fff1; height: 65px;">
    <p class="modal-title d-flex" style="font-size: 1.6rem ; font: Poppins; letter-spacing: 1px;color: #17C653;
;font-weight: 500;">
        <span class="material-symbols-outlined fs-2 py-3">
            check_circle
            </span><span class="mt-3 mx-2">Congratulations</span></p>
    <button type="button px-3" class="btn-close" title="Close"></button>
  </div>
  <div class="p-3">
    <p class="fs-5 text-dark">
        Your request has been received successfully. Our Team will contact you shortly.
    </p>
  </div>
  <div class="p-3 text-end">
    <button class="btn btn-dark px-4 py-2" >Ok</button>
  </div>

  