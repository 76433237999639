import { Component, OnInit } from '@angular/core';
import { Pages } from 'src/enums/pages.enum';
import { PageChangeService } from 'src/services/page-change.service';

@Component({
  selector: 'app-contact-sales',
  templateUrl: './contact-sales.component.html',
  styleUrls: ['./contact-sales.component.scss']
})
export class ContactSalesComponent implements OnInit {
  activeTab = Pages.Home;
  PageFlow = Pages;
  constructor(
    public pageService: PageChangeService
  ) { }

  ngOnInit() {
  }
  setActiveTab(tab: number) {
    this.pageService.pageFlow$.next(tab)
  }
}
