<div class="container d-flex flex-column align-items-center">
  <div class="row mt-5 justify-content-center w-100">
    <div class="col-md-11 mb-4 d-flex align-items-stretch">
      <div class="w-100 border border-gray-00" style="border-radius: 10px;">
        <div class="card-body">
          <p class="fs-5 text-dark card-title">Personal details</p>
          <p class="fs-6 text-dark card-text mt-3">First name: vishwa</p>
          <p class="fs-6 text-dark card-text">Last Name : Netha</p>
          <p class="fs-6 text-dark card-text">Gender : Male</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2 justify-content-center w-100">
    <div class="col-md-11 mb-4 d-flex align-items-stretch">
      <div class="w-100 border border-gray-300" style="border-radius: 10px;">
        <div class="card-body">
          <p class="fs-5 text-dark card-title">Contact information</p>
          <p class="fs-6 text-dark card-text mt-3">Contact: +91 8074821357wa</p>
          <p class="fs-6 text-dark card-text">vishwa@cixcent.com</p>
          <p class="fs-6 text-dark card-text">11-3-135/34/35, Saibaba colony, 500058</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2 justify-content-center w-100">
    <div class="fs-6 text-dark col-md-11 mb-4 d-flex align-items-stretch">
      <div class="w-100 border border-gray" style="border-radius: 10px;">
        <div class="card-body">
          <p class="fs-5 text-dark card-title">Qualification details</p>
          <mat-accordion>
            <mat-expansion-panel class="expansion-panel-spacing">
              <mat-expansion-panel-header>
                <p class="text-muted card-title">SSC/Equivalent*</p>
              </mat-expansion-panel-header>
              <p class="card-text text-dark mt-5">Amaravathi grammar high school</p>
              <p class="card-text text-muted">Year of pass: <span class="text-dark">2017</span></p>
              <p class="card-text text-muted">Percentage: <span class="text-dark">84</span></p>
              <p class="card-text text-muted">Location: <span class="text-dark">Hyderabad</span></p>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel class="expansion-panel-spacing">
              <mat-expansion-panel-header>
                <p class="text-muted card-title">12th/Intermediate/Diploma*</p>
              </mat-expansion-panel-header>
              <p class="card-text text-dark mt-5">Amaravathi college</p>
              <p class="card-text text-muted">Year of pass: <span class="text-dark">2020</span></p>
              <p class="card-text text-muted">Percentage: <span class="text-dark">84</span></p>
              <p class="card-text text-muted">Location: <span class="text-dark">Hyderabad</span></p>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel class="expansion-panel-spacing">
              <mat-expansion-panel-header>
                <p class="text-muted card-title">Degree/Graduation*</p>
              </mat-expansion-panel-header>

              <p class="card-text text-dark mt-5">Jntu</p>
              <p class="card-text text-muted">Year of pass: <span class="text-dark">2023</span></p>
              <p class="card-text text-muted">Percentage: <span class="text-dark">60</span></p>
              <p class="card-text text-muted">Location: <span class="text-dark">Hyderabad</span></p>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel class="expansion-panel-spacing">
              <mat-expansion-panel-header>
                <p class="text-muted card-title">Post graduation*</p>
              </mat-expansion-panel-header>
              <p class="card-text text-dark mt-5">osmania university</p>
              <p class="card-text text-muted">Year of pass: <span class="text-dark">2024</span></p>
              <p class="card-text text-muted">Percentage: <span class="text-dark">84</span></p>
              <p class="card-text text-muted">Location: <span class="text-dark">Hyderabad</span></p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2 justify-content-center w-100">
    <div class="col-md-11 mb-4 d-flex align-items-stretch">
      <div class="w-100 border border-gray" style="border-radius: 10px;">
        <div class="card-body">
          <p class="fs-5 text-dark card-title">Professional details</p>
          <p class="fs-6 text-dark card-text mt-5">Work experience: Yes</p>
          <p class="fs-6 text-dark card-text">Company name: Arrixon technologies</p>
          <p class="fs-6 text-dark card-text">Position: Java developer <span class="text-muted">(Dec2022 -
              May2024)</span></p>
          <p class="fs-6 text-dark card-text">Details: I am a passionate and creative UI/UX designer with 1 years of
            experience in
            creating intuitive
            and engaging digital experiences.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2 justify-content-center w-100">
    <div class="col-md-11  mb-4 d-flex align-items-stretch">
      <div class="w-100 border border-gray" style="border-radius: 10px;">
        <div class="card-body">
          <p class="fs-5 text-dark card-title">Resume</p>
          <p class="fs-6 text-dark card-text mt-5">Resume method: Attached resume file</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2 justify-content-center w-100 mb-4">
    <div class="col-md-5 text-start">
      <button type="button" class="btn btn-outline-dark"
        (click)="redirect(careerFlow.ProfessionalDetails)">Back</button>
    </div>
    <div class="col-md-6 text-end">
      <button type="submit" class="btn btn-dark" (click)="setActiveTab(careerFlow.Complete)">Next</button>
    </div>
  </div>
</div>