import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { RetailManagementComponent } from './components/pages/retail-management/retail-management.component';
import { MobileAppComponent } from './components/pages/mobile-app/mobile-app.component';
import { BullianAppComponent } from './components/pages/bullian-app/bullian-app.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CareerComponent } from './components/pages/career/career.component';
import { BussinessComponent } from './latest-components/bussiness/bussiness.component';
import { RetailPageComponent } from './latest-components/retail-page/retail-page.component';
import { AboutUsLatestComponent } from './latest-components/about-us-latest/about-us-latest.component';
import { ContactUsLatestComponent } from './latest-components/contact-us-latest/contact-us-latest.component';
import { WholeSaleLatestComponent } from './latest-components/whole-sale-latest/whole-sale-latest.component';

const routes: Routes = [
    // {path: '', component: HomeComponent},
    // {path: 'contact', component: ContactComponent},
    // {path: 'career', component: CareerComponent},
    // {path: 'faq', component: FaqComponent},
    // {path: 'error', component: ErrorComponent},
    // {path: 'pix10x', component: RetailManagementComponent},
    // {path: 'mobile-app', component: MobileAppComponent},
    // {path: 'bullian-app', component: BullianAppComponent},
    // {path: 'gallery', component: GalleryComponent},
    // {path: '**', component: ErrorComponent}
    {path: 'home', component: BussinessComponent},
    {path: 'erp24k', component: RetailPageComponent},
    {path: 'wholesale', component: WholeSaleLatestComponent},
    {path: 'aboutUs', component: AboutUsLatestComponent},
    {path: 'contactUs', component: ContactUsLatestComponent},
    { path: '',   redirectTo: '/home', pathMatch: 'full' },
    {path: '**', component: ErrorComponent}
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}