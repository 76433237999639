<header [ngClass]="{'scrolled': isScrolled}" class="fixed-top">
  <div class="container-lg">
    <nav class="navbar navbar-expand-lg navbar-light p-3 " [class.sticky]="isSticky">
      <a class="navbar-brand" href="#" (click)="setActiveTab(PageFlow.Home)">
        <img src="assets/img/Cixcent Logo1.svg" alt="Logo" style="filter: brightness(0) invert(1);">
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Cixcent</h5>
          <button type="button" class="btn-close text-reset " data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body justify-content-end scrollable-menu">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="btn btn-dark  m-1 sfw-bolder" data-toggle="tab" data-bs-dismiss="offcanvas" aria-label="Close"
                href="#product">Products</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-dark sfw-bolder m-1" data-toggle="tab" data-bs-dismiss="offcanvas" aria-label="Close"
                href="#contactus">Contact US</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-dark sfw-bolder m-1" data-toggle="tab" data-bs-dismiss="offcanvas" aria-label="Close"
                href="#aboutus">About us</a>
            </li>
            <li class="nav-item">
              <a href="#scrollspyHeading1" class="btn btn-light text-dark m-1" data-toggle="tab"
                (click)="setActiveTab(PageFlow.BookDemo)" data-bs-dismiss="offcanvas" aria-label="Close">
                Book demo
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
<div class="space"></div>
<div>
  <ng-template [ngIf]="activeTab === PageFlow.Home">
    <app-home-page></app-home-page>
  </ng-template>
  <ng-template [ngIf]="activeTab === PageFlow.ContactSupport">
    <app-contact-support></app-contact-support>
  </ng-template>
  <ng-template [ngIf]="activeTab === PageFlow.ContactSales">
    <app-contact-sales></app-contact-sales>
  </ng-template>
  <ng-template [ngIf]="activeTab === PageFlow.Career">
    <app-career-details></app-career-details>
  </ng-template>
  <ng-template [ngIf]="activeTab === PageFlow.BookDemo">
    <app-demo-form></app-demo-form>
  </ng-template>
</div>
<div class="container d-flex justify-content-end align-items-center ">
  <a href="#" (click)="redirectToWhatsApp()"><img src="assets/new/whatsapp1.svg" class="whatsapp cursor-pointer"
      alt=""></a>
</div>