import { Component, OnInit } from '@angular/core';
import { Careers } from 'src/enums/pages.enum';
import { PageChangeService } from 'src/services/page-change.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  activeTab = Careers.PersonalDetails;
  careerFlow = Careers;

  constructor(public pageService: PageChangeService) { }

  ngOnInit() {
    this.redirect;
  }
  redirect(tab: number) {
    this.pageService.careerFlow$.next(tab);
  }
  setActiveTab(tab: number) {
    this.pageService.careerFlow$.next(tab)
  }
}
