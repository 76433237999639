import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Pages } from 'src/enums/pages.enum';
import { PageChangeService } from 'src/services/page-change.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  activeTab = Pages.Home;
  PageFlow = Pages;
  constructor(
    public pageService: PageChangeService
  ) { }

  ngOnInit() {
   
  }
  
  setActiveTab(tab: Pages) {
    this.pageService.pageFlow$.next(tab)
  }

}

