import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  constructor(private router: Router) {}

  ngOnInit() {
    {
    }
  }
  ngAfterViewInit(): void {
    this.addIntersectionObserver();
  }

  addIntersectionObserver() {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add 'show' class to trigger animation
            entry.target.classList.add("show");
            observer.unobserve(entry.target); // Stop observing after animation
          }
        });
      },
      { threshold: 0.3 }
    ); // Adjust the threshold as needed

    // Observe all elements that need to be animated
    document
      .querySelectorAll(
        ".left-side-animation, .top-side-animation, .bottom-side-animation, .right-side-animation,.image_animation"
      )
      .forEach((element) => {
        observer.observe(element);
      });
  }
  onSearch(){
    this.router.navigate(['/blog/blogs']);
  }
  onReadMore(){
    this.router.navigate(['/blog/blog-one'], { queryParams: { from: 'home' } });
  }
  readMore(){
    this.router.navigate(['/blog/blog-two'], { queryParams: { from: 'home' } })
  }
  onMore(){
    this.router.navigate(['/blog/blog-three'], { queryParams: { from: 'home' } })
  }
}
