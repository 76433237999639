import { Component, HostListener, OnInit } from '@angular/core';
import { Pages } from 'src/enums/pages.enum';
import { PageChangeService } from 'src/services/page-change.service';
@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {

  title = 'cixcentwebsite';
  isSticky: boolean = false;
  isScrolled: boolean = false;
  activeTab = Pages.Home;
  PageFlow = Pages;
  constructor(public pageService: PageChangeService) {}

  ngOnInit() {
    const orderFlowSub = this.pageService.pageFlow$.subscribe((page: Pages) => {
      this.activeTab = page;
    });
  }

  setActiveTab(tab: number) {
    this.activeTab = tab;
  }

  @HostListener('window:scroll', [])
  onScroll() {
      this.isScrolled = window.scrollY > 0;
  }
  redirectToWhatsApp() {
    const phoneNumber = '9000106052';
    const message = 'Hello!';
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  }
}
