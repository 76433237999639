<app-navbar-two></app-navbar-two>

<div class="page-title-area" style="padding-bottom: 36px;
padding-top: 10px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Bullian Apps</h2>

                    <ul>
                        <li><a routerLink="/">Product</a></li>
                        <li>Bullian Apps</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="features-section pt-5 pb-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="features-area-content">
                    <span>Bullian App</span>
                    <h2 style="font-size: 30px;">A new way to Share images for Jewellary Industry.</h2>
                    
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="flaticon-claim"></i>
                                </div>
                                <h3>Upload & View Images</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="flaticon-claim"></i>
                                </div>
                                <h3>Categorize Images</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="flaticon-claim"></i>
                                </div>
                                <h3>Share with Customer/Retailer</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="flaticon-claim"></i>
                                </div>
                                <h3>Get Order of Shared Images</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="flaticon-claim"></i>
                                </div>
                                <h3>Mobile Apps</h3>
                                <p>We Provide Mobile Apps which are available on Playstore & App store</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3 benefit-area-content">
                                <div class="icon">
                                    <i class="flaticon-claim"></i>
                                </div>
                                <h3>Web App</h3>
                                <p>We provide Web Platform to exhibit jewellery items among wholeseller, retailers and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-section pt-5 pb-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="saying-title">
                <h3>Subscribe to Pix10x</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et
                    dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 card-hover">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Free Plan</h3>
                    </div>

                    <div class="price">
                        ₹0.00/- <span></span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Upto 3 months</li>
                        <li><i class="fa fa-arrow-right"></i> Can upload upto <b>1,000</b> Images</li>
                        <li><i class="fa fa-arrow-right"></i> No Charges</li>
                        <li><i class="fa fa-arrow-right"></i> 1 user for Both Mobile and Web.</li>
                    </ul>

                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Subscribe now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 card-hover">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Montly Plan (Premium)</h3>
                    </div>

                    <div class="price">
                        ₹ 999/ <span>+ 18% GST (Monthly)</span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Every month</li>
                        <li><i class="fa fa-arrow-right"></i> Can upload upto <b>5,000</b> Images</li>
                        <li><i class="fa fa-arrow-right"></i> No additional charges</li>
                        <li><i class="fa fa-arrow-right"></i> 2 user for Both Mobile and Web.</li>
                    </ul>

                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Subscribe now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 card-hover">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Montly Plan (Basic)</h3>
                    </div>

                    <div class="price">
                        ₹ 499/ <span>+ 18% GST (Monthly)</span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Every month</li>
                        <li><i class="fa fa-arrow-right"></i> Can upload upto <b>2,500</b> Images</li>
                        <li><i class="fa fa-arrow-right"></i> No additional charges</li>
                        <li><i class="fa fa-arrow-right"></i> 2 user for Both Mobile and Web.</li>
                    </ul>

                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Subscribe now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 card-hover">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Yearly Plan (Pro)</h3>
                    </div>

                    <div class="price">
                        ₹11,999/ <span>+ 18% GST (Monthly)</span>
                    </div>

                    <ul class="pricing-features">
                        <li><i class="fa fa-arrow-right"></i> Every month</li>
                        <li><i class="fa fa-arrow-right"></i> Can upload upto <b>7,500</b> Images</li>
                        <li><i class="fa fa-arrow-right"></i> No additional charges</li>
                        <li><i class="fa fa-arrow-right"></i> 2 user for Both Mobile and Web.</li>
                    </ul>


                    <div class="buy-btn">
                        <a routerLink="/" class="buy-btn-one">Subscribe now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>