import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErrorComponent } from "./components/pages/error/error.component";
import { BlogOneComponent } from "./module/blog/blog-one/blog-one.component";
import { BlogTwoComponent } from "./module/blog/blog-two/blog-two.component";
import { BlogThreeComponent } from "./module/blog/blog-three/blog-three.component";
import { BlogFourComponent } from "./module/blog/blog-four/blog-four.component";
import { BlogSixComponent } from "./module/blog/blog-six/blog-six.component";
import { BlogFiveComponent } from "./module/blog/blog-five/blog-five.component";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () => import("./module/home/home.module").then((m) => m.HomeModule),
    data: {
      moduleName: "home",
    },
  },
  {
    path: "product",
    loadChildren: () => import("./module/product/product.module").then((m) => m.ProductModule),
    data: {
      moduleName: "product",
    },
  },
  {
    path: "company",  
    loadChildren: () => import("./module/company/company.module").then((m) => m.CompanyModule),
    data: {
      moduleName: "company",
    },
  },
  {
    path: "features",
    loadChildren: () => import("./module/features/features.module").then((m) => m.FeaturesModule),
    data: {
      moduleName: "features",
    },
  },
  {
    path: "blog",
    loadChildren: () => import("./module/blog/blog.module").then((m) => m.BlogModule),
    data: {
      moduleName: "blog",
    },
  },

  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "**", component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
