import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output,EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscribable, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PageChangeService, validateFormFields } from 'src/services/page-change.service';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-retail-page',
  templateUrl: './retail-page.component.html',
  styleUrls: ['./retail-page.component.scss']
})
export class RetailPageComponent implements OnInit {
  customerDemoDetails: FormGroup;
  subscription: Subscription[] = [] ;
  private apiUrl = environment.apiUrl
  constructor(
    private fb: FormBuilder,
    // private modalService: NgbModal,
    private readonly _snackBar: MatSnackBar,
    private http: HttpClient,
    private pageService: PageChangeService,
    private el: ElementRef
    
  ) { }

  ngOnInit() {
    this.customerDemoDetails = this.fb.group({
      name: new FormControl(null,[Validators.required]),
      mobileNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
      storeName: new FormControl(null,[Validators.required]),
      email: new FormControl(null)
    })
    this.pageService.scrollToDemo$.subscribe((scroll)=>{
      if(scroll){
        this.scrollToSection();
      }
    })
  }

  get f(){
    return this.customerDemoDetails.controls;
  }

  scrollToSection(): void {
    const element = document.getElementById("target-section");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  // viewInfo() {
  //   const modalRef = this.modalService.open(ModalInfoComponent, {
  //     size: 'sm',
  //     keyboard: false,
  //     backdrop: 'static',
  //     windowClass: 'custom-modal', 
  //   });
  //   // modalRef.componentInstance.client = client;
  //   modalRef.result.then(
  //     () => {},
  //     () => {},
  //   );
  // }


  bookDemo(payLoad: any): Observable<any> {
    const url = `${this.apiUrl}/Account/DemoBooking`;
      return this.http.post<any>(url, payLoad).pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return throwError(() => new Error('error while adding Billing'));
        }),
      );
    }

    onSubmit() {
      const formData = this.customerDemoDetails.value;
      if(this.customerDemoDetails.invalid){
        validateFormFields(this.customerDemoDetails);
        return;
      }
      const payLoad = {
        contactName: formData.name,
        businessName: formData.storeName,
        mobileNumber: formData.mobileNumber,
        createdOn: new Date(),
      };
      const sbDemo = this
       .bookDemo(payLoad)
        .subscribe((response) => {
          if (response?.success) {
            this._snackBar.open(' Your request has been received successfully !. Our Team will contact you shortly.', 'Close', {
              duration: 2000,
            });
            setTimeout(() => {
              this.customerDemoDetails.reset();
            }, 500);
          }else{
            
          }
        }
       );
      this.subscription.push(sbDemo);
    }
  

}
