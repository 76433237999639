<div class="container-md mt-5">
  <div class="form-container">
    <form [formGroup]="professionalDetailsForm" class="mt-5" (ngSubmit)="setActiveTab(careerFlow.Preview)">
      <div class="row mb-4">
        <div class="col-md-6">
          <p class="fs-5 text-dark">Work experience</p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <label class="form-label text-dark">
            <p class="fs-6 text-dark">Do you have previous work experience?</p>
          </label>
          <div class="d-flex gap-3">
            <mat-chip-list aria-label="Experience selection">
              <mat-chip class="chip border border-grey w-custom" formControlName="selectedExperience"
                [ngClass]="{'selected': selectedExperience === 'yes'}" (click)="selectedWork('yes')" selectable="true"
                [removable]="false">
                Yes
                <span *ngIf="selectedExperience === 'yes'" class="checkmark">&#10003;</span>
              </mat-chip>

              <mat-chip class="chip border border-grey w-custom" formControlName="selectedExperience"
                [ngClass]="{'selected': selectedExperience === 'no'}" (click)="selectedWork('no')" selectable="true"
                [removable]="false">
                No
                <span *ngIf="selectedExperience === 'no'" class="checkmark">&#10003;</span>
              </mat-chip>
            </mat-chip-list>
          </div>

          <div
            *ngIf="professionalDetailsForm.get('selectedExperience')?.invalid && professionalDetailsForm.get('selectedExperience')?.touched"
            class="text-danger">
            Please select an option.
          </div>
        </div>
      </div>

      <!-- Dynamic experience details -->
      <div *ngIf="selectedExperience === 'yes'" formArrayName="experience">
        <div *ngFor="let qualification of experience.controls; let i = index" [formGroupName]="i">
          <div class="row mb-4">
            <div class="col-md-12">
              <label class="form-label text-dark">
                <p class="fs-6 text-dark">Experience details</p>
              </label>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control form-control-lg" formControlName="companyName"
                  placeholder="Company Name*" required>
                <div
                  *ngIf="experience.at(i).get('companyName')?.invalid && experience.at(i).get('companyName')?.touched"
                  class="text-danger">
                  Company Name is required.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control form-control-lg" formControlName="position"
                  placeholder="Position*" required>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control form-control-lg" formControlName="from" placeholder="From*"
                  required>
                <div *ngIf="experience.at(i).get('from')?.invalid && experience.at(i).get('from')?.touched"
                  class="text-danger">
                  From date is required.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control form-control-lg" formControlName="to" placeholder="To*" required>
                <div *ngIf="experience.at(i).get('to')?.invalid && experience.at(i).get('to')?.touched"
                  class="text-danger">
                  To date is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="form-group">
                <input type="text" class="form-control form-control-lg" formControlName="description"
                  placeholder="Provide short description about your work*" required>
              </div>
            </div>
          </div>
        </div>

        <mat-button-toggle-group aria-label="Experience Actions">
          <mat-button-toggle class="experience-toggle" (click)="addExperience()">Add Experience</mat-button-toggle>
          <mat-button-toggle class="experience-toggle" (click)="removeLastExperience()"
            [disabled]="experience.length <= 1">Remove</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!-- File or URL input -->
      <div class="row mb-4 mt-5">
        <div class="col-mb-2">
          <div>
            <mat-button-toggle-group aria-label="Experience Actions">
              <mat-button-toggle class="experience-toggle" (click)="showFileInput()">Attach Resume file</mat-button-toggle>
              <mat-button-toggle class="experience-toggle" (click)="showUrlInput()">Paste Resume URL</mat-button-toggle>
            </mat-button-toggle-group>

          </div>
        </div>
      </div>

      <div *ngIf="showFileUpload">
        <div class="row mb-4">
          <div class="col-md-12">
            <div class="dropzone cursor-pointer" appFileDragNDrop>
              <div class="text-wrapper text-center">
                <div class="centered w-100">
                  <input type="file" name="resumeFile" (change)="onFileChange($event)" accept=".pdf,.doc,.docx" />
                  <p>Drop files to attach or browse</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showUrlInputField">
        <div class="row mb-4">
          <div class="col-md-12">
            <div class="form-group">
              <input type="text" class="form-control form-control-lg" placeholder="Paste or enter URL here"
                formControlName="urlInput" />
              <div
                *ngIf="professionalDetailsForm.get('urlInput')?.invalid && professionalDetailsForm.get('urlInput')?.touched"
                class="text-danger">
                URL is required.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6 text-start">
          <button type="button" class="btn btn-outline-dark"
            (click)="redirect(careerFlow.QualificationDetails)">Back</button>
        </div>
        <div class="col-md-6 text-end">
          <button type="submit" class="btn btn-dark" (click)="setActiveTab(careerFlow.Preview)">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>