import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-blog-six',
  templateUrl: './blog-six.component.html',
  styleUrls: ['./blog-six.component.scss']
})
export class BlogSixComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }
  onBack() {
    this.route.queryParams.subscribe(params => {
      const pageNumber = +params['page'] || 1;

      if (params['from'] === 'home') {
        this.router.navigate(['/home']);
      } else {
        this.router.navigate(['/blog'], { queryParams: {page: pageNumber } });
      }
    });
  }
}
